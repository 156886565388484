@media screen and (min-width: 768px) and (max-width: 1186px) {
  .forum-home-head {
    padding-left: 4vh;
  }
  .feedback_single_wrap {
    flex-direction: column !important;
  }
  .single_rating {
    flex-direction: column !important;
  }
  .rating {
    padding-bottom: 100px;
  }
  .feedback-comment {
    margin-left: 0 !important;
  }
  .feedback_single_wrap {
    padding-bottom: 0 !important;
  }
  .tags-forum-home {
    margin-left: 0 !important;
  }
  .tags-forum-home {
    border-left: 10px solid #121212 !important;
  }
  .news-home2 {
    display: block;
    margin: 14vh 3vh 0 3vh;
  }
  .news-home {
    display: none !important;
  }
  .tags-forum-home {
    font-size: 1.4vh !important;
  }
  .tags-more {
    display: none !important;
  }
  .forum-banner-cover p {
    font-size: 1.5vh;
  }
  .forum-banner-cover-content {
    margin-left: 0 !important;
    flex-wrap: wrap !important;
  }
  .forum-banner-text {
    height: 30% !important;
  }
  .tags-container {
    padding-left: 0 !important;
    flex-wrap: wrap;
    width: 50vh !important;
  }
  .edit-rich-button-container {
    margin-top: 25vh !important;
  }
  .forum-home-link-forum {
    padding: 8em 0 0 10em !important;
  }
  .home {
    padding-left: unset !important;
    padding-right: unset !important;
    padding-top: 4vh;
    padding-bottom: 0 !important;
  }
  .head-icons {
    justify-content: center !important;
  }

  .cart {
    padding: 0 !important;
  }
  .collection .collection-item {
    margin-top: 1em;
  }
  .bm-menu-wrap {
    width: 100vw !important;
  }
  .subforum-list {
    padding-left: 0 !important;
  }
  .trending-card img {
    min-width: 15vh !important;
  }
  .item-desc {
    display: block;
  }
  .service {
    padding-bottom: 3em;
  }
  .service-cards {
    display: block;
    width: 80%;
  }
  .height-define {
    display: inline;
  }
  .leaflet-left {
    left: unset;
  }
  .accesories-main {
    display: flex !important;
    align-content: center;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
  }
  .accesories-main img {
    margin-top: 5vh;
    width: 68vw !important;
    margin-bottom: 5vh;
  }
  .accesories-carousel {
    width: 100vw;
  }
  .carousel .thumbs {
    padding-left: 0 !important;
  }
  .rs {
    flex-direction: column;
    width: 80%;
  }
  .sos {
    display: none;
  }
  .cart-price {
    bottom: 50px;
    width: 100%;
    padding: 0.5em;
  }
  .leaflet-top {
    top: 8em;
  }
  .footer-secondary {
    padding-bottom: 13vh;
    padding-top: 6vh;
  }
  .footer-primar-quick p {
    text-align: center;
  }
  .footer-primary {
    padding: 4vh;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-primar-quick {
    width: 100%;
  }
  .footer-primar-logo {
    width: 100%;
  }
  .forum-home {
    padding-bottom: 3vh !important;
  }
  .rating {
    padding-top: 4em !important;
  }
  .feedback-input {
    width: 80vw;
  }
  .ant-rate {
    font-size: 40px !important;
  }
  .search-bar-wrap {
    padding: 1em;
    padding-top: 0;
    display: none;
  }
  .search-bar {
    width: 81vw;
  }
  .search-image-button {
    width: 14vh;
    border: none;
  }
  .search-image {
    width: 13vh;
    border: 1px solid white;
    border-radius: 8px;
  }
  .search-content-direction {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-mobile {
    padding: 8em 1em 1em 1em !important;
  }
  .status-bar {
    justify-content: center;
    align-items: center;
    padding-top: unset;
  }
  .status-component {
    width: 50% !important;
  }
  .ant-popover-inner {
    width: 71vw;
  }
  .ant-popover-buttons button {
    width: 20vw;
  }
  .ant-popover-inner-content {
    height: auto;
  }
  .news-home2 {
    display: block !important;
  }
  .news {
    display: none !important;
  }
  .news-home {
    padding-left: 11vh !important;
    padding-top: 14vh !important;
  }
  .home-banner {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      #121212 !important;
    height: 45vh !important;
    width: 100%;
  }
  .carousel .slide img {
    height: 28vh;
  }
  .ant-tabs-top {
    padding: 0 !important;
  }
  .footer-primary {
    height: 24vh !important;
  }
  .explore-link {
    right: 1.1em !important;
  }
  .account-main {
    padding: unset;
    padding: 1em;
  }
  .prof-detail {
    width: 100%;
  }

  .table-content tr,
  td {
    padding-left: unset !important;
  }
  .ant-tabs-nav-container-scrolling {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 11px !important;
  }
  .accnt-scrty {
    width: unset;
    padding: 3em;
  }
  .accnt-help {
    text-align: center;
  }
  .table {
    flex-direction: column;
  }

  .accnt-upload {
    padding-bottom: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .accnt-upload img {
    margin-top: 5vh;
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 13px;
  }
  .account-head {
    text-align: center;
    padding: 0 !important;
    padding-top: 3em !important;
  }
  .content-home > .news-home2 > .carousel img {
    height: 16vh !important;
    object-fit: cover;
  }
  .content-home > .news-home > .carousel img {
    height: 16vh !important;
    object-fit: cover;
  }
  .forum-tiles {
    justify-content: center;
  }
  .forum-home-thread-square-textbox p {
    line-height: 1 !important;
    margin-bottom: 10px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
  }
  .home-banner-text {
    height: 15vh;
  }
  .home-content-part1 img,
  .home-content-part2 img {
    width: 24vh;
    height: 24vh;
  }

  .home-content {
    padding-top: 11vh;
  }

  .home-content h5 {
    padding-top: 13vh !important;
  }
  .head-icons {
    padding-left: 18vh;
  }
  .head-icons img {
    width: 25vh !important;
  }
  .home-content-part2 {
    padding-top: 37vh;
  }
  .cart_content {
    flex-direction: column;
  }
  .detail_wrap {
    width: 100% !important;
    padding-left: 34px;
  }
  .cart_items_wrap {
    padding-top: unset !important;
  }
  .summry {
    margin-top: 24px;
  }
  .booking_summary {
    width: unset !important;
  }
  .recom-acc {
    display: block;
  }
  .recom-se {
    display: block;
  }
  .content-home {
    padding-top: 0;
  }
  .carousel .slider-wrapper {
    height: 40vh;
  }
  .carousel .slide .testcar {
    bottom: unset !important;
  }
  .trending-thread-content {
    width: 27vh !important;
    padding-right: 2vh;
    height: 20vh;
  }
  .trending-thread-content h6 {
    white-space: pre-line;
    text-overflow: ellipsis;
  }
  .testcar {
    width: 80vw !important;
  }
  .head-icons {
    margin-bottom: 1vh;
    padding-top: 44px !important;
  }

  .header {
    width: 100% !important;
    max-width: 100vw;
    padding-right: 1em;
    display: none;
  }
  .headerstore-firstlogo {
    margin-left: 4vh !important;
    width: 7vw !important;
  }
  .headerstore-secondlogo {
    width: 20em;
    margin-right: 2vh;
  }
  .home-thread a {
    margin-right: 2vh;
  }
  a.skip-btn {
  }
  .signin-logos-line {
    justify-content: center !important;
  }
  .signin-logo-head {
    height: 14vh !important;
  }
  .header-wrap {
    display: flex;
    /* justify-content: center;
        align-items:center; */
  }
  .breadcrumb {
    margin-left: 1em;
  }
  .carousel-subhead {
    font-size: 25px !important;
    bottom: 80px;
    left: 100px;
  }
  .el-head {
    left: 80px;
    bottom: 50px;
    font-size: 25px;
    font-family: Montserrat;
  }
  .carousel-head1 {
    font-size: 25px;
    bottom: 70px;
    left: 100px;
  }

  .carousel.carousel-slider {
    height: 40vh;
    width: 98% !important;
  }
  .carousel .control-dots {
    padding-right: 6em !important;
  }
  .carousel .slide img {
    width: 100%;
  }

  .testcar h3 {
    font-size: 17px;
    font-weight: bold;
  }
  .testcar p {
    font-size: 9px !important;
  }
  .newnav {
    display: none;
  }
  .recc-pro {
    flex-direction: column;
  }
  .products-individual {
    width: 100%;
    margin-bottom: 1em;
  }
  .home-content {
    background-image: unset;
  }
  .banner {
    padding: 0;
    padding-top: 7em;
    margin-left: 0 !important;
  }
  .carousel .carousel-status {
    display: none;
  }
  .content-home {
    height: auto;
  }
  .home-icons {
    padding-bottom: 4em !important;
  }
  .home-icons-wrap {
    padding: 0;
    /* background-image: url("./vector-icons/hexagon.png"); */
    background-repeat: repeat-y;
    background-size: contain;
  }
  .home-second-content-wrap {
    flex-direction: column;
    /* align-items: center; */
  }
  .home-second {
    /* justify-content: center;
        align-items: center; */
    padding: 0;
    margin: 3em 1em 2em 1em;
  }
  .home-second-part1 h1 {
    font-size: 64px !important;
  }
  .home-second-content {
    width: 100%;
    justify-content: space-around;
  }
  .home-second-second {
    width: 100%;
    justify-content: space-around;
  }
  .head-icons {
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 3em;
    justify-content: space-around !important;
  }
  .head-icons img {
    width: 22vh !important;
  }
  .home-content-wrap h5 {
    padding-left: 1.5em !important;
  }
  .home-content-part1,
  .home-content-part2 {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    background-repeat: unset;
    background-repeat: repeat-y;
    justify-content: space-around;
    /* background-image: url("./vector-icons/hexagon.png"); */
    padding: 14px;
  }
  .home-content {
    height: auto;
  }
  .home-content h5 {
    padding-top: 8.99vh;
  }
  .home-content-part1 img .home-content-part2 img {
    width: 10em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .home-accessories {
    width: 12em;
    height: 12em;
    margin-left: 0;
  }
  .home-accessories img {
    width: 5em;
  }
  .home-content-wrap {
    padding-bottom: 8.99vh;
    margin: 0;
  }
  .home-icons-wrap h5 {
    padding-left: 1.5em !important  ;
  }
  .home-content-wrap h5 {
    padding-left: 1.5em;
  }
  .home-facebook-content {
    padding: 1em;
  }
  .home-facebook {
    padding-bottom: 3em;
  }

  .home-icons {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    height: 40vh;
  }

  .news-home2 {
    padding: 0;
  }
  .news-home {
    padding: 0;
    padding-bottom: 3em;
  }

  .service_content {
    flex-direction: column;
    padding-bottom: 12vh;
  }
  .service_caption {
    margin-bottom: 0 !important;
  }
  .service_explore {
    top: 55vh !important;
  }
  .product_explore {
    top: 122vh !important;
  }
  .service_container {
    width: 100% !important;
  }
  .service_container img {
    width: 100% !important;
  }
  .headerstore-firstlogo {
    width: 20vw !important;
  }
  .service_main_wrap {
    padding-left: 0 !important;
  }
  .trending-image-home {
    width: 17vh;
  }
  .accessories-moto .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 15px;
    font-weight: 500;
  }
  .headerstore-content {
    padding: 0 0 0 0;
  }

  .accessories-moto .nav-tabs {
    margin-left: 0;
    margin-right: 0;
  }
  .accessories-moto .nav-tabs .nav-item {
    font-size: 14px;
  }
  .acc-main h2 {
    font-size: 24px;
  }
  .acc-main p {
    font-size: 12px;
  }
  .accessories-moto .nav-tabs .nav-link:hover {
    border: none;
  }
  .acc-main {
    min-height: 40vh;
    padding: 2em;
    margin-bottom: 3em;
  }
  .height-define {
    height: auto;
  }
  .acc-main {
    width: 100% !important;
  }
  .bottommenu {
    display: flex;
  }
  .payment-content {
    flex-direction: column !important;
    padding: 1em 1em;
  }
  .payment-method,
  .payment-objects {
    width: 100% !important;
  }
  .payment-method img {
    width: 23em !important;
  }
  .card {
    width: 40vw !important  ;
  }
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 5em;
  }
  .cart {
    padding-left: 0 !important;
  }
  .collection p {
    display: none !important;
  }
  .height-define {
    padding: 0 !important;
    padding-top: 4em !important;
  }
  .box {
    flex-direction: row;
  }
  .ant-comment-avatar img {
    width: 4vh !important;
    height: 4vh !important;
    overflow: hidden;
  }
  .home-wrap {
    flex-direction: column !important;
  }
  .forum-threads {
    padding: 0 !important;
    padding-bottom: 0 !important;
  }
  .ant-space-align-center {
    margin-top: 10vh;
  }
  .forum-more-container {
    top: 15vh;
    right: 0vh;
  }
  .ant-popover-inner {
    height: auto !important;
    width: auto !important;
  }
  .ant-picker-panel-container {
    margin-left: 2vh !important;
    width: 60% !important;
  }
  .forum-home {
    padding-top: 16vh;
  }
  .forum-home-thread {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }
  .forum-home-thread p {
    height: 9vh;
    line-height: 3.6vh;
  }
  .forum-home-thread h5 {
    font-size: 15px !important;
  }
  .forum-home-thread img {
    height: 16vh !important;
    width: 19vh !important;
  }
  .price {
    margin-bottom: 6vh;
  }
  .addtocart {
    font-size: 15px !important;
  }
  .rs {
    width: 60% !important;
  }
  .rs h3 {
    font-size: 20px;
  }
  .cost {
    font-size: 15px;
  }
  .forum-home {
    background-size: contain;
    padding-bottom: 7em;
    padding-right: unset;
    padding-left: unset;
  }
  .payment-content {
    padding-top: 4em !important;
  }
  .payment-method {
    padding-bottom: 4em;
  }
  .threadlist {
    padding: 0 !important;
    padding-top: 8em !important;
  }
  .threadlist .forum-threadtwo {
    padding-top: 0 !important;
  }
  .threadlist-head {
    padding-left: 1.3em !important;
  }
  .forum-home-thread-square2 img {
    width: 42vh !important;
    max-width: 50vh;
  }
  .forum-banner-text h3 {
    font-size: 2vh !important;
  }
  .tag-pop-wrap {
    margin-top: 2vh;
    margin-bottom: 2vh;
    justify-content: center;
    align-items: center;
  }
  .forum-more {
    display: flex !important;
  }
  .forum-more-share {
    display: flex;
  }
  .ant-comment-actions > li > span,
  a:not([href]):not([tabindex]) {
    font-size: 10px;
    padding-right: 1em;
  }
  .forum-video {
    width: 100% !important;
    height: 100% !important;
  }
  .content-home .forum-threadtwo {
    margin: 0 !important;
  }
  .clubhome-posters {
    flex-direction: column;
    align-items: center;
  }
  .forum-home-thread-square img {
    width: 24em !important;
    max-width: 25em !important;
  }
  .home-thread {
    margin-bottom: 0 !important;
    margin-left: 1em !important;
    margin-right: 0 !important;
    margin-top: 2vh !important;
  }
  .trending-card-sub h5 h2 strong {
    font-size: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }
  .trending-card-sub h5 h2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  .forum-threadtwo {
    padding-top: 0 !important;
    margin: 2vh !important;
    flex-direction: column !important;
    padding: 1em 1em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .expand-mobile-share-btn {
    display: inline-block !important;
  }
  .ant-modal-close-x {
    display: none !important;
  }

  .forum-home-link-forum {
    padding-top: 0 !important;
  }
  .forum-home-thread-square {
    width: 24em;
    margin-top: 1em;
    min-height: unset;
  }
  .forum-home-thread img {
    width: 50vw;
    height: 15vh;
  }
  .forum-home-thread p {
    font-size: 10px;
  }
  .forum-home-thread h6 {
    font-size: 15px;
  }
  .forumcontentone {
    padding: unset !important;
  }
  .forum-home-thread {
    width: unset !important;
  }
  .forum-head-image {
    width: 100% !important;
  }

  .forum-head-image img {
    width: 87vw !important;
  }
  .thread-share {
    display: none !important;
  }
  .threadexpand {
    flex-direction: column !important;
  }
  .thread-expanded-trending {
    position: unset !important;
    padding: 2vh !important;
    margin-bottom: 3vh;
    margin-left: 0 !important;
    width: unset !important;
    height: unset !important;
    overflow: unset !important;
    background-color: #121212;
  }

  .forum-home-firstpart {
    flex-direction: column !important;
    padding: 0 !important;
    padding-top: 5em !important;
    padding-bottom: 5em !important;
  }
  .clubhomemain {
    padding: unset !important;
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 375px !important;
  }

  .timeline-content-images {
    flex-direction: column !important;
  }
  .timeline-account-detail {
    width: unset !important;
  }
  .forum-service-now {
    width: 10em;
  }
  .threadexpand-content {
    padding: 2vh !important;
  }
  .thread-expanded-image {
    width: 42vh !important;
  }
  .threadexpand-content h5 {
    text-align: unset;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon,
  .anticon svg {
    font-size: 12px !important ;
  }
  .commenting-forum {
    width: 90% !important;
  }
  .seeall-forum {
    padding-top: 3em;
  }
  .forum-threads {
    padding: 1em !important;
    margin-bottom: 8.99vh !important;
  }
  .trending-thread {
    height: 20vh;
  }
  .trending-thread p {
    font-size: 1.3vh !important;
    height: unset !important;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 6.7vh;
  }
  .trending-thread h6 {
    font-size: 15px !important;
  }
  .forum-home .forum-threadtwo {
    margin-bottom: 10vh;
  }
  .seeall-forum {
    bottom: -4em;
    right: 1em;
  }
  .testcar p {
    display: none;
  }
  .testcar h3 {
    font-size: 2vh;
    padding-top: 1vh;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    margin-left: 0 !important;
  }
  .forum-home-second-div {
    display: flex;
    flex-direction: column !important;
  }
  .thread-expanded-trending2 {
    margin-left: 2vh !important;
    margin-top: 2vh;
  }
  .forum-home-thread-square {
    margin-left: 0 !important;
    height: 45vh;
  }

  .thread-expand-gallery {
    height: 230px !important;
    margin-right: 10px !important;
  }
  .home-content {
    padding: 0 !important;
  }
  .home-content-part1,
  .home-content-part2 {
    padding: 0 !important;
  }
  .explore-link {
    padding-top: 3vh;
  }
  .home-content-part1 img,
  .home-content-part2 img {
    width: 24vh !important;
    height: 24vh !important;
    padding-top: 5vh;
    object-fit: cover;
  }
  .css-f12czy {
    height: 20em !important;
  }
  .thread-create {
    padding: 2vh !important;
    padding-bottom: 10vh !important;
    padding-top: 14vh !important;
  }
  .threadexpand-wrap {
    width: unset !important;
    margin-left: 0 !important;
  }
  .commenting-forum .ant-comment {
    width: unset !important;
  }
  .ant-spin-nested-loading {
    padding-left: unset !important;
  }
  .ant-comment {
    padding-left: unset !important;
  }
  .commenting-forum {
    width: 100% !important;
  }
  .ant-comment-inner {
    padding-right: 3vh !important;
    padding-left: 2vh !important;
  }
  .ant-list-split .ant-list-header {
    padding-left: 3vh;
    padding-right: 2vh;
  }
  .ant-list-items .ant-comment {
    padding-left: 1em !important;
  }
  .thread-create-field {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    padding-top: 0 !important;
  }
  .thread-create-imagefield {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    justify-self: space-between !important;
  }
  .thread-create-upload {
    width: 50% !important;
  }
  .thread-create-title {
    width: 100% !important;
  }
  .thread-create-textfield {
    width: 100% !important;
  }
  .thread-create-content {
    width: 100% !important;
  }
  .threadexpand {
    padding: 0;
    padding-bottom: 5em;
  }
  .threadexpand-content {
    justify-content: center;
    align-items: left !important;
    width: unset !important;
    padding-top: 8em !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
  .mobile-signout {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-forum-threads {
    margin-top: 0 !important;
  }
  .forum-home-thread-square2 {
    width: 41vh;
  }
  .feedback_home {
    margin: 5vh 10vh 0 10vh !important;
  }
  .service_list {
    width: unset !important;
  }
  .container_main {
    padding-top: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
    margin-left: unset !important;
    padding-bottom: 9.5vh;
  }
  .service_cover_img {
    width: 100%;
  }
  .isfeatured {
    margin-left: 2vh;
    color: white;
  }
  .trending-thread-name-n-time p {
    width: 70% !important;
  }
  .create-forum-button {
    width: 12em;
  }
  .create-forum-button-cancel {
    width: 8em;
  }
  .public-DraftEditor-content[contenteditable="true"] {
    padding: 3vh;
  }
  .thread-title-container2 {
    height: auto !important;
  }
  .rich-editor-header-image {
    flex-direction: column;
  }
  .carousel .control-dots .dot {
    opacity: 0.3 !important;
  }
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1 !important;
  }
  .ck.ck-editor {
    width: 40vh !important;
  }
  .thread-title {
    width: 95%;
  }
  .thread-title-sml {
    width: 95%;
  }
  .forum-n-sub {
    width: 40vh !important;
  }
  .forum-n-sub select {
    width: 24vh;
  }
  .thread-create-imagefield-firstdiv {
    width: 40vh;
  }
  .thread-create-imagefield {
    width: 95% !important;
  }
  .thread-create-rich {
    padding: unset !important;
    padding-top: 8em !important;
    padding-bottom: 8em !important;
  }

  .create-thread-button-wrap {
    margin-top: 30vh !important;
  }
  .ImageSpan__root___RoAqL {
    background-size: contain !important;
    width: 30vh !important;
    height: 20vh !important;
  }
  .RichTextEditor__root___2QXK- {
    height: 35vh !important;
    width: 40vh !important;
  }
  .IconButton__icon___3YgOS {
    background-size: 14px;
  }
  .InputPopover__root___3Hpj9 {
    left: -103px !important;
    top: calc(100% + 54px) !important;
  }
  .rdw-editor-wrapper {
    width: 95% !important;
    height: 50vh;
  }
  .rdw-editor-main {
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
    height: 50vh !important;
  }
  .rdw-editor-toolbar {
    background: white !important;
    border: none !important;
  }
  .forum-threadtwo > .news > .carousel .slide img {
    height: 40vh !important;
  }
  .forum-threadtwo > .news-home > .carousel .slide img {
    height: 40vh !important;
  }
  .forum-threadtwo > .news-home2 > .carousel .slide img {
    height: 40vh !important;
  }
  .banner-image-forum {
    height: 50vh !important;
  }
  .rdw-editor-main img {
    width: 30vh !important;
  }
  .thread-expand-data img {
    width: 42vh !important;
  }
  .carousel-root {
    width: 100% !important;
  }
  .news-home {
    display: none;
  }
  .news {
    display: none;
  }
  .news-home2 {
    display: block !important;
  }
  .home-banner {
    height: 55vh !important;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      #121212 !important;
  }
  .carousel .slide .testcar {
    bottom: unset !important;
  }
  .testcar h3 {
    font-size: 2.5vh;
  }
  .home-carousel-cover {
    margin-left: 10vw;
    padding-top: 10vh;
  }
  .carousel.carousel-slider {
    height: 55vh;
  }
  .home-forum-threads {
    display: none;
  }
  .home-content-part1 img,
  .home-content-part2 img {
    width: 23vh;
    height: 23vh;
  }
  .home-content-part2 {
    padding-top: 22vh;
  }
  .home-content {
    height: auto;
  }
  .MuiTypography-alignCenter {
    text-align: right !important;
  }
  .forum-trending-sidebar {
    display: none;
  }
  .forum-home-thread-square {
    margin-left: 4vh !important;
  }
  .thread-create-rich {
    padding-left: 20vw;
  }
  .thread-create-imagefield,
  .thread-title,
  .rdw-editor-wrapper {
    width: 80% !important;
  }
}
