@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

@font-face {
  font-family: Praktika;
  src: url("assets/Fonts/praktika-light-webfont.woff") format("woff");
  font-family: Metro;
  src: url("assets/Fonts/METRO-DF.woff") format("woff");
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
/* html {
  overflow: auto;
} */
/* Change the white to any color ;)
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2f2826 inset !important;
}
/*Change text in autofill textbox*/
/* input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}  */

::-webkit-scrollbar {
  width: 3px;
  background-color: black;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(151, 151, 151, 0.356);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.ant-col-12 {
  max-width: unset !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: white;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: white;
}
.ant-select-arrow {
  color: white !important;
  width: 7px;
  height: 4px;
}

.MuiTypography-alignCenter {
  text-align: right !important;
}

/* STYLES FOR NAVIGATION */

a,
button,
input {
  outline: none !important;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fc5c2e;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
button.button-edit {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #111;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.bm-overlay {
  width: 100vw !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  height: 90% !important;
  padding-top: 8em;
}
.bm-item-list a {
  color: white;
  text-decoration: none;
  padding-top: 4vh;
}
.bm-item-list :hover {
  color: grey;
  text-decoration: none;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.signout-nav {
  position: absolute;
  bottom: 4em;
}
.signout-nav:hover {
  color: white !important;
}
.profile-nav {
  position: absolute;
  top: 2em;
  padding-left: 1em;
}
.profile-icon {
  font-size: 40px;
}
.news-home2 {
  display: none;
}
.map_drawer {
  display: none;
  position: fixed;
  right: 0;
  top: 10vh;
  z-index: 100000;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .forum-home-head {
    padding-left: 4vh;
  }

  .row_list_service {
    width: unset !important;
  }
  .below_header {
    display: none !important;
  }

  #sign_in_carousel {
    display: none !important;
  }
  .inivitation_card {
    flex-direction: column;
  }
  .map_drawer {
    display: block;
  }
  .feedback_single_wrap {
    flex-direction: column !important;
  }
  .single_rating {
    flex-direction: column !important;
  }
  .rating {
    padding-bottom: 100px;
  }
  .feedback-comment {
    margin-left: 0 !important;
  }
  .feedback_single_wrap {
    padding-bottom: 0 !important;
  }
  .tags-forum-home {
    margin-left: 0 !important;
  }

  .news-home2 {
    display: block;
    margin: 14vh 3vh 0 3vh;
  }
  .news-home {
    display: none !important;
  }
  .tags-forum-home {
    font-size: 1.4vh !important;
  }
  .tags-more {
    display: none !important;
  }
  .forum-banner-cover p {
    font-size: 1.5vh;
  }
  .forum-banner-cover-content {
    margin-left: 0 !important;
    flex-wrap: wrap !important;
  }
  .forum-banner-text {
    height: 30% !important;
  }
  .tags-container {
    padding-left: 0 !important;
    flex-wrap: wrap;
    width: 50vh !important;
  }
  .edit-rich-button-container {
    margin-top: 25vh !important;
  }
  .forum-home-link-forum {
    padding: 8em 0 0 10em !important;
  }
  .home {
    padding-left: unset !important;
    padding-right: unset !important;
    padding-top: 4vh;
    padding-bottom: 0 !important;
  }
  .head-icons {
    justify-content: center !important;
  }

  .cart {
    padding: 0 !important;
  }
  .collection .collection-item {
    margin-top: 1em;
  }
  .bm-menu-wrap {
    width: 100vw !important;
  }
  .subforum-list {
    padding-left: 0 !important;
  }
}
/* STYLES FOR NAVIGATION */

.collection .collection-item {
  position: relative;
}
.collection {
  background: transparent !important;
}
/*width changed 80% */

.cart-price {
  height: 10vh;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #241814;
  padding: 1.5em;
  display: flex;
  left: 0;
}
button.skip-btn {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
.btn {
  position: absolute;
  right: 2em;
}
.ant-notification-notice {
  background: #333333 !important;
  color: white !important;
  border-radius: 8px !important;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  color: #fc5c2d !important;
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg,
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  color: black !important;
}
.ant-alert.ant-alert-closable {
  border-radius: 8px;
  margin-top: 3vh;
}
.item-desc p {
  position: absolute;
  right: 40%;
}

/* STYLES FOR MAP */
.map {
  height: 70vh;
  width: 100%;
}
.ant-modal-content {
  background-color: #2a2a2a !important;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #fc5c2e !important;
}
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  color: white !important;
}
.ant-btn {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
}
.ant-btn-primary {
  background-color: #ff6f00 !important;
  border: none !important;
  color: white !important;
}
.ant-btn-primary:hover {
  background-image: linear-gradient(
    102.99deg,
    #fc5c2e 0%,
    #fcaa2e 100%
  ) !important;
  color: white !important;
}

.transform {
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
}
/*

.transform-active {
  border-radius: 100px;
 height: 30vh;
 width:15vw;
 
} */

/* STYLES FOR MAP */

/* STYLES FOR SERVICE-CUSTOMER */

@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
.service {
  /* background-color: black; */
  padding-top: 0.002em;
  width: 100%;
}

.srvc-frst-img img {
  width: 100%;
  height: auto;
}
.service-cards:hover {
  cursor: pointer;
}
.service-cards {
  padding: 0.8em;
}
.service-cards img {
  width: 30vh;
}
.service-whole {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.service-cards h4 {
  position: absolute;
  font-family: Montserrat;
  bottom: 0;
  right: 0;
}
.service-content {
  padding: 8em;
}
.srvc-buttons {
  display: flex;
  flex-direction: row;
}
.steam-wrap {
  padding: 2em;
  position: relative;
}
.steam-wrap h2 {
  padding-left: 4em;
  color: white;
  font-size: 27px;
  font-family: Montserrat;
}
.steam-content {
  text-align: center;
}
.steamcleaning-cards {
  display: inline-block;
  margin: 2em;
  position: relative;
}
.steamcleaning-cards h5 {
  text-align: center;
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: 16em;
  left: 6em;
  font-size: 15px;
}
.card:hover {
  cursor: pointer;
}
.collection .collection-item {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.08),
    rgba(255, 255, 255, 0.08)
  );
  border-radius: 8px;
  margin-top: 12px;
}

.accesories-main img {
  width: 25vw;
}
.accesories-moto {
  height: 100vh !important;
}
.otp-input input {
  width: 3em !important;
  height: 4em !important;
  color: white !important;
  padding-left: none !important;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 16px !important;
  border: none;
  padding-left: 20px;
}
.MuiTypography-colorTextSecondary {
  text-align: right;
  color: white !important;
}
.otp-input {
  margin-top: 5vh;
  margin-bottom: 1vh;
}
.card {
  background-color: transparent !important ;
  width: 10vw !important;
}
.ant-btn {
  height: 3em !important;
}

.card-content {
  display: none;
}
.steamcleaning-cards a {
  color: white !important;
  font-size: 20px;
}
.steam-icons:hover {
  cursor: pointer;
}
.steamcleaning-cards a:hover {
  cursor: pointer;
}
.breadcrumb {
  background-color: rgb(35, 24, 20) !important;
  margin-left: 4em;
  padding: 1.75rem 1rem !important;
  padding-bottom: 0 !important;
}
.breadcrumb li {
  font-size: 13px;
}
.breadcrumb li a {
  color: white;
}
.ecu-bg {
  background-image: url("./assets/ecu.png");
  height: 60vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-text {
  color: white;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .trending-card img {
    min-width: 15vh !important;
  }
  .item-desc {
    display: block;
  }
  .service {
    padding-bottom: 3em;
  }
  .service-cards {
    display: block;
    width: 80%;
  }
}

/* STYLES FOR SERVICE-CUSTOMER */

/* STYLES FOR ACCESORIES */

.MuiBox-root-1 {
  margin-top: 24px;
  text-align: right !important;
}
.nav {
  display: flex !important;
  justify-content: center;
  justify-content: space-evenly;
}
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: transparent !important;
  border: none;
  border-bottom: 3px solid #fc5c2d !important;
  font-size: 1.1em;
}
.nav-tabs .nav-link {
  color: grey;
  font-size: 1.1em;
}

.accesories {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}
.accesories-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 50%;
}
.accesories-detail {
  width: 50%;
}
.price {
  padding-top: 0.5em;
  width: 100%;
  background-color: rgb(37, 24, 20);
  color: white !important;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  padding-bottom: 2vh;
  position: absolute;
}
.accesories-moto {
  position: relative;
}

.rs {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: white !important;
}
.rs h3 {
  font-weight: 900;
  font-family: Montserrat;
  color: white !important;
  font-style: normal;
  font-size: 30px;
}
.estimate {
  padding-right: 1em;
  font-weight: lighter;
  color: white !important;
}
.shipping {
  text-decoration: underline;
  color: white !important;
}
.cost {
  color: white !important;
  font-weight: 900;
  font-family: Montserrat;
  padding-left: 1em;
}
.map-sos {
  display: flex;
  flex-direction: column;
  padding: 140px 6vw;
}
.map-sos h3 {
  padding-top: 60px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: 0.18px;

  color: #ffffff;
}
.leaflet-left {
  top: 8em;
  left: 78px;
}
.sos {
  width: 100%;
  color: white;
  background-color: rgb(31, 30, 30);
  display: flex;
  flex-direction: column;
  padding-top: 10em;
}
.sos-content1 {
  text-align: justify;
  padding: 5em;
  font-family: Montserrat;
  padding-top: 0 !important;
}
.sos-content1 h3,
.sos-content2 h4,
.sos-content3 h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.15px;

  color: #ffffff;
}
.sos-content2 {
  border-bottom: 1px solid rgb(129, 129, 129);
  text-align: justify;
  padding-left: 5em;
  padding-top: 1.8em;
  height: 20%;
  font-family: Montserrat;
}
.sos-content2 p,
.sos-content3 p {
  font-size: 10px;
  color: rgb(202, 201, 201);
}
.sos-content3 {
  text-align: justify;
  padding-left: 5em;
  padding-top: 1.8em;
  height: 100%;
  font-family: Montserrat;
}
.sos-btn {
  position: absolute;
  bottom: 3em;
  border: none;
  background-color: red;
  color: white;
  border-radius: 23px;
  width: 10em;
  height: 3em;
  z-index: 1000;
}
.sos-btn:hover {
  box-shadow: 7px 7px 4px rgba(5, 5, 5, 0.25);
  background-color: rgb(253, 33, 33);
  width: 10.1em;
  height: 3.1em;
}
.height-define {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 6em;
  padding-top: 2em;
  background-color: linear-gradient(
      144.37deg,
      rgba(240, 92, 45, 0.08) 0%,
      rgba(255, 255, 255, 0) 60.15%
    ),
    #121212 !important;
}
.accesories-main {
  width: 50%;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .height-define {
    display: inline;
  }
  .service_centers button {
    width: 80px !important;
    font-size: 12px !important;
  }
  .ant-collapse {
    width: unset !important;
  }
  .service_main_wrap {
    padding-bottom: 20vh !important;
    padding-top: 80px !important;
  }
  .detaile_wrap {
    width: unset !important;
  }
  .subscribe_modal {
    flex-direction: column !important;
  }
  .subscribe_part2 h1 {
    font-size: 64px !important;
  }
  .service_container img {
    object-fit: contain !important;
    height: auto !important;
  }
  .policy_box {
    width: unset !important;
  }
  .sos_mob {
    display: flex !important;
    background-color: unset !important;
    padding-top: unset !important;
  }
  .sos_mob_content1 {
    padding: unset !important;
  }
  .map-sos svg {
    font-size: 42px !important;
    fill: rgb(117, 115, 115);
  }
  .leaflet-left {
    left: unset;
  }
  .accesories-main {
    display: flex !important;
    align-content: center;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
  }
  .accesories-main img {
    margin-top: 5vh;
    width: 68vw !important;
    margin-bottom: 5vh;
  }
  .accesories-carousel {
    width: 100vw;
  }
  .carousel .thumbs {
    padding-left: 0 !important;
  }
  .rs {
    flex-direction: column;
    width: 80%;
  }
  .sos {
    display: none !important;
  }
  .sos_mob {
    display: flex !important;
  }

  .cart-price {
    bottom: 50px;
    width: 100%;
    padding: 0.5em;
  }
  .leaflet-top {
    top: 8em;
  }
}

/* STYLES FOR ACCESOIRES */

/* STYLES FOR SIGNUPFORM */

.signupform {
  padding-top: 3em;
}
.signupform h1 {
  text-align: center;
}
.signup {
  height: auto;
  min-height: 100vh;
  padding-top: 5em;
  background: #121212;
}
.alice-carousel {
  /* height: 80vh; */
}
.alice-carousel__wrapper {
  /* display: flex !important;
  justify-content: center !important; */
}
.carousel .control-dots {
  text-align: right !important;
  padding-right: 4em !important;
}
.forms-signup {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
}
.forms-signup h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;

  color: #ffffff;
}

.alice-carousel__wrapper {
  /* padding-top: 1em; */
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon,
.anticon svg {
  color: #f05c2d;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.75px;
}

.forms-signup input {
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.08);
  height: 58px;
  width: 334px;
  padding-left: 1.5em;
  margin-top: 1em;
  color: white !important;
}
.input-main {
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.08);
  height: 58px;
  width: 334px;
  padding-left: 1.5em;
  margin-top: 1em;
  color: white;
}
.forms-signup select {
  height: 58px !important;
}
.search-bar-content {
  border-radius: 8px 8px 8px 8px !important;
}
.search-bar-wrap {
  padding: 15vh 2vh 0 2vh;
}
.search-bar {
  border: none;
  border-radius: 8px 0 0 8px;
  background-color: rgba(255, 255, 255, 0.08);
  height: 3em;
  width: 100%;
  padding-left: 1.5em;
  color: white;
}
.search-content-direction {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-image-button {
  width: 26vh;
  margin: 2em;
  border: 1px solid white;
  border-radius: 8px;
  cursor: pointer;
}
.search-image {
  width: 24vh;
}

.search-image-button:active {
  border: 1px solid #f05c2d;
}

.search-button {
  height: 3em;
  width: 3em;
  border: none;
  border-radius: 0 8px 8px 0;
  background-color: rgba(255, 255, 255, 0.08);
}
.search-button svg {
  color: rgba(255, 255, 255, 0.38) !important;
}
.alice-carousel__next-btn-item {
  color: #fc5c2e !important;
}
.alice-carousel__prev-btn-item {
  color: #fc5c2e !important;
}
.alice-carousel__dots-item.__active {
  background-color: #fc5c2e !important;
}
.uploadform {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
.uploadform h1,
.forms-signup h1 {
  font-family: Montserrat;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.37);
  line-height: 87%;
  letter-spacing: -0.5px;
  font-feature-settings: "kern" off;
}
.threadexpand-content {
  position: relative;
}
.threadexpand-content div strong {
  color: white;
}
.like-button-forum svg {
  position: absolute;
  top: 38%;
  right: 34%;
  font-size: 30px;
  cursor: pointer !important;
}

.signup-upload {
  border-radius: 160px;
  background-color: #c4c4c4;
  height: 40vh;
  width: 20vw;
  /* border:1px solid red; */
  position: relative;
}
.upload-icon {
  font-size: 190px;
  text-align: center;
  margin-top: 5vh;
  margin-left: 5vh;
  color: white;
}
.upload-ic {
  position: absolute;
  color: #fc5c2e;
  font-size: 60px;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.upload-submit {
  margin-top: 3em;
  background-image: #ff6f00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  border: none;
  width: 20vw;
  height: 6vh;
  border-radius: 8px;
}
.upload-submit:hover {
  color: white;
}

/* STYLES FOR SIGNUP */
.ant-collapse-borderless {
  background: transparent !important;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  color: #f05c2d !important;
  border: none !important;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  border-radius: 8px !important;
  border-color: transparent;
}
/* STYLES FOR SIGNIN */

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: white !important;
  padding-top: 0px !important;
  padding-left: 15px !important;
}
button.button-resend {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  background-color: #121212;
  color: #fc5c2e;
}
.ant-select-arrow {
  top: 51% !important;
  right: 24px !important;
}

.signin {
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #121212;
}
.signin-logos {
  padding-bottom: 8.99vh;
}

.signin-logos-line {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.signin-btn {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  height: 10px;
  width: 16px;
  outline: 0;
  font-family: monospace;
  box-shadow: 0px 5px 10px black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 4px solid #d9d9d9;
}
.signin-btn:hover {
  box-shadow: 0px 15px 25px -5px black;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  color: black !important;
}

.signin-btn:active {
  box-shadow: 0px 4px 8px black;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-form input {
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: white;
  border-radius: 12px;
  border: none;
  width: 23em;
  height: 58px;
  padding-left: 2em;
  margin-top: 1em;
  font-family: Montserrat;
}
.date_picker_signup {
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: white !important;
  border-radius: 12px !important;
  border: none !important;
  width: 334px !important;
  height: 58px !important;
  margin-top: 1em !important;
  font-family: Montserrat !important;
}
.date_picker_signup {
  color: white !important;
}
.date_picker_account {
  background-color: white !important;
  color: unset;
  width: 100%;
  height: 32px;
}
.otp-input input {
  width: 23em !important;
  height: 4em !important;
  color: white !important;
  padding-left: none !important;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  border: none;
}
.login-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* padding-top: 8.99vh; */
}
.login-btns button {
  background: #ff6f00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: none;
  margin-top: 1em;
  height: 7vh;
  width: 20em;
  font-family: Montserrat;
  color: black;
  font-weight: 600;
}
.login-btns button:hover {
  color: white;
}

.forgot-signin {
  position: absolute;
  color: white !important;
  font-family: Montserrat;
  cursor: pointer;
  bottom: 2em;
}
.forgot-signin:hover {
  color: #fc5c2e !important;
}

/* STYLES FOR SIGNIN */

/* STYLES FOR FEEDBACK */

.rating {
  min-height: 100vh;
  color: white;
  position: relative;
  display: flex;
  padding-left: 10vw;
  background: #121212;
  flex-direction: column;
  padding-bottom: 50px;
}
.feedback_single_wrap {
  display: flex;
  flex-direction: row;
  border: 1px solid #747474;
  border-radius: 12px;
  padding: 12px 24px;
  width: 90%;
  margin-top: 24px;
}
.rating h3 {
  color: white;
  font-family: Montserrat;
  font-weight: 900;
  padding-top: 2em;
}
.submit_feedback {
  background: #ff6f00;
  height: 46px;
  border-radius: 16px;
  border: none;
  margin-top: 24px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-transform: capitalize;
}
.feedback {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.single_rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single_rating .anticon svg {
  font-size: 20px !important;
}
.ant-rate-star.ant-rate-star-full,
.ant-rate-star.ant-rate-star-zero,
.ant-rate-star.ant-rate-star-half.ant-rate-star-active {
  transition: transform 0s;
  color: #ff6f00 !important;
}
.single_rating h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0 !important;
  padding-right: 12px;
}
.ant-rate {
  font-size: 30px !important;
  text-align: center;
}
.feedback-comment {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 48px;
}
.feedback-input {
  width: 240px;
  height: 128px;
  background-color: rgba(250, 248, 248, 0.16);
  border: none;
  padding: 1.8em;
  color: white;
  display: block;
  outline: none;
  font-family: Montserrat;
  text-align: center;
  margin-left: 5vh;
}
.feedback-btn {
  border-radius: 8px;
  color: black !important;
  border: none;
  background: #ff6f00;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 30px;
  width: 10em;
  font-weight: 600;
  padding-top: 5px;
  text-align: center;
  margin-top: 5vh;
}
.feedback-btn:hover {
  color: white !important ;
  font-weight: bold;
}
.feedback-btn svg {
  height: 3em;
  width: 3.4em;
}
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer-primary {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20vh !important;
  padding: 0 20vh 0 20vh;
}
.footer-bg {
  width: 100%;
  background-color: #362a27 !important;
  background-image: url("./vector-icons/footerhex.svg");
  background-size: cover;
}
.footer-primar-quick {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-primar-quick p {
  color: rgba(255, 255, 255, 0.87);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.footer-primar-quick-content {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-primar-logo {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-primar-logo img {
  width: 11vh;
}
.quick-link {
  color: rgba(255, 255, 255, 0.6);
  font-style: normal;
  font-weight: 600;
  font-size: 1.6vh;
  line-height: 16px;
}
.footer-secondary {
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.04),
      rgba(255, 255, 255, 0.04)
    ),
    #121212;
}
.footer-secondary p {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6vh;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .footer-secondary {
    padding-bottom: 13vh;
    padding-top: 6vh;
  }
  .feedback-input {
    margin-left: 0;
  }
  .feedback-comment {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-left: 48px;
  }
  .footer-primar-quick p {
    text-align: center;
  }
  .footer-primary {
    padding: 4vh;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-primar-quick {
    width: 100%;
  }
  .footer-primar-logo {
    width: 100%;
  }
  .forum-home {
    padding-bottom: 3vh !important;
  }
  .rating {
    padding-top: 4em !important;
  }
  .feedback-input {
    width: 80vw;
  }
  .ant-rate {
    font-size: 40px !important;
  }
  .search-bar-wrap {
    padding: 1em;
    padding-top: 0;
    display: none;
  }
  .search-bar {
    width: 81vw;
  }
  .search-image-button {
    width: 14vh;
    border: none;
  }
  .search-image {
    width: 13vh;
    border: 1px solid white;
    border-radius: 8px;
  }
  .search-content-direction {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-mobile {
    padding: 8em 1em 1em 1em !important;
  }
}

/* STYLES FOR FEEDBACK */

/* STYLES FOR PAYMENT */

button.StripeCheckout {
  background: none !important;
  background-color: white !important;
  background: #fc5c2e !important;
}
.StripeCheckout span {
  background-image: none !important;
  background-color: black;
}

/* STYLES FOR STATUSBAR */

@import url("https://fonts.googleapis.com/css?family=Montserrat");

.status-bar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
}
.status-component {
  width: 60% !important;
}
html,
body {
  font-family: "Montserrat" !important;
}
body {
  font-family: Montserrat !important;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: -3px !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  display: none !important;
}
.ant-steps-item-wait .ant-steps-item-icon,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgb(51, 51, 51) !important;
}
.ant-steps-item-process .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #ff6f00 !important;
  border: #fc5c2e;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: black !important;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:last-child
  .ant-steps-item-title,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: white !important;
}

.ant-steps-item-title::after {
  height: 10px !important;
  border-radius: 5px;
  margin-top: -4px;
}
.ant-popover-inner-content {
  color: white !important;
  height: auto !important;
  padding: 0 0 0 0 !important;
}
.ant-popover-inner-content button {
  width: 100% !important;
  cursor: pointer;
  border: none;
  color: white;
  background: transparent;
}
.ant-popover-inner-content button:hover {
  background: rgba(240, 92, 45, 0.8) !important;
}
.ant-popover-inner {
  border-radius: 8px !important;
  height: 30vh;
}
.ant-popover-buttons {
  padding-top: 5em;
}
.container {
  height: auto !important;
  position: relative !important;
}
.ant-popover-buttons button {
  color: black;
  background-image: #ff6f00;
  border: none;
  font-family: Montserrat;
  border-radius: 4px;
  width: 11vw;
  height: 6vh;
  color: white !important;
}
.ant-popover-buttons button:hover {
  background-image: #ff6f00;
  color: white;
}
.ant-popover-message-title {
  font-size: 15px;
}
.ant-message-notice-content {
  background: #1c1c1c !important;
  color: white !important;
}
.ant-message {
  border-radius: 8px !important;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .status-bar {
    justify-content: center;
    align-items: center;
    padding-top: unset;
  }
  .status-component {
    width: 50% !important;
  }

  .ant-popover-buttons button {
    width: 20vw;
  }
  .ant-popover-inner-content {
    height: auto;
  }
}
/* STYLES FOR STATUSBAR */

/* STYLES FOR ACCOUNT */
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  border-bottom: 1px solid yellow !;
}

.ant-tabs-top > .ant-tabs-nav::before {
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  display: none !important;
}
.account-main {
  padding: 6em;
  padding: 0 5em 0 12em;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: white !important;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  background: #fc5c2e !important;
}
.ant-tabs-nav .ant-tabs-tab {
  color: rgb(255, 255, 255) !important;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  background: #fc5c2e !important;
}
.prof-detail {
  width: 90%;
}
.prof-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 0.08);
  width: 100%;
}
.prof-detail h5 {
  color: rgba(255, 255, 255, 0.38);
  padding-left: 2em;
  margin-bottom: 0 !important;
}
.table {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* width: 638px !important; */
  width: 100%;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 51px 31px;
  position: relative;
}
.table_wrap h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;

  color: #ffffff;
  padding-left: 2em;
}
.table td {
  color: white;
  height: 20px;
  border-top: none;
  padding-top: 2em;
  border-top: none;
}
.edit-accnt {
  color: #fc5c2e !important;
}
.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}
.ant-upload-list-picture-card-container {
  position: absolute !important;
}
.ant-upload.ant-upload-select-picture-card {
  position: absolute;
  top: 30em;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload-list-picture-card .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 50% !important;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 140px !important;
  width: 140px !important;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0 !important;
}
.ant-upload.ant-upload-select-picture-card {
  margin-top: 3em;
  margin-left: 1em;
  height: 5vh !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  background-image: linear-gradient(
    102.99deg,
    #fc5c2e 0%,
    #fcaa2e 100%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none !important;
  border-radius: 8px !important;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 0 !important;
  color: black;
  font-family: Montserrat;
  font-weight: 600;
}

.accnt-upload {
  margin-right: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 196px;
  width: 196px;
  border-radius: 50%;
}
.accnt-upload img {
  max-width: 30vh;
  height: 166px;
  width: 166px;
  border-radius: 50%;
  max-height: 35vh;
  object-fit: cover;
}

.accnt-scrty {
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 60%;
  position: relative;
}
.accnt-scrty h6 {
  color: white;
  padding-bottom: 1em;
}
.accnt-scrty input {
  border: none;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  padding: 1em;
  height: 5vh;
  margin-bottom: 1em;
  border-radius: 8px;
  color: white;
}
.button-security {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  flex-direction: row;
  margin-left: auto;
  padding-bottom: 2em;
}
.button-security button {
  background: #ff6f00 !important;
  font-family: Montserrat;
  border: none;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  padding: 5px;
  border-radius: 8px;
}
.accnt-scrty td {
  color: white;
}

.accnt-help {
  display: flex;
  flex-direction: column;
}
.accnt-help h5 {
  color: white;
  font-size: 16px;
}
.accnt-help p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}
.accnt-help h6 {
  color: white;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.accnt-help a {
  color: white !important;
}
.ant-tabs-top {
  padding-top: 3.99vh !important;
}
.explore-link svg {
  color: #fc5c2d !important;
}
.news-home2 {
  display: none !important;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .news-home2 {
    display: block !important;
  }
  .news {
    display: none !important;
  }
  .news-home {
    padding-left: 11vh !important;
    padding-top: 14vh !important;
  }
  .home-banner {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      #121212 !important;
    height: 45vh !important;
    width: 100%;
  }
  .carousel .slide img {
    height: 28vh;
  }
  .ant-tabs-top {
    padding: 0 !important;
  }
  .footer-primary {
    height: 24vh !important;
  }
  .explore-link {
    right: 1.1em !important;
  }
  .account-main {
    padding: unset;
    padding: 1em;
  }
  .prof-detail {
    width: 100%;
  }

  .table-content tr,
  td {
    padding-left: unset !important;
  }
  .ant-tabs-nav-container-scrolling {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 11px !important;
  }
  .accnt-scrty {
    width: unset;
    padding: 3em;
  }
  .accnt-help {
    text-align: center;
  }
  .table {
    flex-direction: column;
  }

  .accnt-upload {
    padding-bottom: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-size: 13px;
  }
  .account-head {
    text-align: center;
    padding: 0 !important;
    padding-top: 3em !important;
  }
  .content-home > .news-home2 > .carousel img {
    height: 16vh !important;
    object-fit: cover;
  }
  .content-home > .news-home > .carousel img {
    height: 16vh !important;
    object-fit: cover;
  }
  .forum-tiles {
    justify-content: center;
  }
  .forum-home-thread-square-textbox p {
    line-height: 1 !important;
    margin-bottom: 10px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
  }
}
div.left-header {
  display: flex;
  justify-content: space-between;
}

div.left-header h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 0.5rem;
}
div.service-details {
  margin-bottom: 30px;
}
div.service-details .ant-tabs-tab {
  margin: 0 0px 0 0 !important;
}
div.service-details .ant-tabs-tab-btn {
  width: 100px;
  height: 120px;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
}

div.box-service {
  width: 100px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
}
div.service-details .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  /* color: white !important; */
  background: radial-gradient(
      100% 100% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.16) !important;
}
/* div.box-service:hover{

  background: radial-gradient(100% 100% at 50% 100%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.16) !important;

} */
/* div.box-service:active{


background: radial-gradient(100% 100% at 50% 100%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.16) !important;

} */
div.aligm {
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 40px;
}
div.wheel-alin {
  margin-top: 40px;
  margin-bottom: 40px;
}
div.wheel-alin span {
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
div.wheel-alin label {
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 0px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
div.aligm h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;

  color: #ffffff;
}
div.wheel-alin {
  display: flex;
}
div.box-service h3 {
  width: 48px;
  height: 40px;
}
div.box-service h3 {
  margin-top: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 68px;
  color: #ffffff;
}
/* STYLES FOR ACCOUNT */

/* STYLES FOR HOME */

div.car-detail {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
button.location {
  display: flex;
  width: 76px;
  height: 32px;
  background: rgba(245, 245, 245, 0.16);
  border-radius: 67px;
  border: none !important;
  align-items: center;
  margin-left: 20px;
}
button.location h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  margin-bottom: 0px;
  letter-spacing: 0.4px;
  margin-left: 7px;
  color: rgba(255, 255, 255, 0.87);
}
div.car-detail .ant-select-selector {
}

div.car-detail
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  display: flex;
  align-items: center;
  background: rgba(245, 245, 245, 0.16);
  border-radius: 67px;
  height: 32px !important;
  width: 100% !important;
  padding-left: 5px !important;
  border-radius: 67px !important;
  border: none !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex !important;
}
h6.carnames {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: 7px;
  margin-bottom: 0px !important;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.87);
}
h6.car-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: 7px;
  margin-bottom: 0px !important;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;

  /* High Emphasis */

  color: rgba(255, 255, 255, 0.87);
}
div.car-detail .ant-select-selector {
  display: flex;
  align-items: center;
  background: rgba(245, 245, 245, 0.16);
  border-radius: 67px;
  height: 32px !important;
  width: 144px !important;
  border-radius: 67px !important;
  border: none !important;
}
div.car-detail .ant-select {
  display: flex;
  align-items: center;
  background: rgba(245, 245, 245, 0.16);
  border-radius: 67px;
  height: 32px !important;
  width: 144px !important;
  border-radius: 67px !important;
  border: none !important;
}
button.car h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 20px;
}

.ant-picker-panel-container .ant-picker-panel {
  width: 90% !important;
}
.css-df17o1 {
  position: fixed !important;
}
.css-42igfv {
  padding-top: 14vh !important;
}
.home {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #121212;
  padding-left: 16vh;
  padding-bottom: 10vh;
  overflow: hidden;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 6em;
  background: linear-gradient(
      0deg,
      rgba(240, 92, 45, 0.08),
      rgba(240, 92, 45, 0.08)
    ),
    #121212;
}
.header-logo {
  width: 6em;
}
.home-second {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 35vh 0 35vh;
}
.home-service-cards {
  width: 2vh;
}
.home-second-second {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-second-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home-second-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 35%;
}
.home-second-part1 {
  display: flex;
  flex-direction: column;
}

.home-icons-wrap {
  padding: 6em;
  padding-bottom: 0;
}
.head-icons {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  /* padding: 0 5em 0 5em; */
  margin-bottom: 8.99vh;
  padding-top: 24px;
  padding-left: 25vh;
  padding-right: 25vh;
}

.head-icons img {
  width: 25vh !important;
  padding-left: 3vh;
  padding-right: 3vh;
}
.news-home2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh;
  padding-top: 5.2vh;
  padding-bottom: 0;
  position: relative;
}
.news-home {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 67px;
  padding-top: 170px;
  padding-bottom: 0;
  position: relative;
}
.legend {
  background: none !important;
  left: 0 !important;
  margin-left: unset !important;
  text-align: start !important;
}

.home-content-wrap {
  margin-top: 0;
}
.home-content-wrap h5 {
  font-family: Montserrat !important;
  font-weight: 600;
  font-size: 2vh;
  color: rgba(255, 255, 255, 0.87);
  padding-left: 10vh;
}
.recom-acc {
  padding-left: 0 !important;
  margin-top: 6vh;
}
.home-content {
  width: 100% !important;
  background-image: url("./vector-icons/main-page-lines.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: fixed;
  position: relative;
  padding: 10vh;
  padding-top: 17vh;
  height: 130vh;
}
.home-content h5 {
  padding-top: 14.07vh;
}
.home-content-part1,
.home-content-part2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  /* background-image: url("./vector-icons/hexagon.png"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.home-content-part2 {
  padding-top: 34vh;
}
.recom-acc {
  display: none;
}
.recom-se {
  display: none;
}
.home-accessories {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 30vh;
  width: 30vh;
  background: #1e1e1e;
  border-radius: 8px;
  margin-left: 2em;
  margin-top: 2em;
  cursor: pointer;
}
.home-accessories img {
  width: 8em;
}

.home-content-part1 img,
.home-content-part2 img {
  width: 25vh;
  height: 25vh;
  cursor: pointer;
  object-fit: cover;
}
.home-banner-text {
  height: 20vh;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
}

@media screen and (min-width: 1920px) {
  .home-banner-text {
    height: 15vh;
  }
  .home-content-part1 img,
  .home-content-part2 img {
    width: 24vh;
    height: 24vh;
  }

  .home-content {
    padding-top: 11vh;
  }

  .home-content h5 {
    padding-top: 13vh !important;
  }
  .head-icons {
    padding-left: 18vh;
  }
  .head-icons img {
    width: 25vh !important;
  }
  .home-content-part2 {
    padding-top: 37vh;
  }
}

.home-content-wrap h5 {
  color: white;
  font-family: Montserrat;
}
.explore-link {
  color: #fc5c2d !important;
  text-decoration: underline !important;
  position: absolute;
  right: 15vh;
  padding-top: 8vh;
}
.home-facebook {
  display: flex;
  justify-content: center;
  height: auto;
}
.home-facebook-content {
  width: 80%;
  height: 8vh;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.home-facebook-content h6 {
  color: white;
}
.home-icons {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.home-icons img {
  width: 10em !important;
}

.home-thread-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.content-home {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding-top: 6vh; */
  /* justify-content: center; */
}

.head-icons figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.head-icons figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer !important;
}

.hvr-float-shadow {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.07),
      rgba(255, 255, 255, 0.08)
    ),
    black;
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow:hover,
.hvr-float-shadow:focus,
.hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before,
.hvr-float-shadow:focus:before,
.hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.hvr-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse-grow:hover,
.hvr-pulse-grow:focus,
.hvr-pulse-grow:active {
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098d1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}
.home-news-forum {
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover,
.hvr-buzz-out:focus,
.hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover,
.hvr-buzz-out:focus,
.hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
  padding-right: 2em;
  position: relative;
  padding-top: 2em;
  margin-left: 2em;
}
.carousel .slide .testcar {
  bottom: 11px !important;
  left: 10px;
  color: white;
  position: absolute;
  text-align: left !important;
}
.testcar {
  width: 100% !important;
}
.testcar h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.5vh;
  font-weight: 600;
  font-family: Montserrat;
}
.testcar p {
  font-size: 3vh;
}
.carousel.carousel-slider {
  height: 60vh;
}

.carousel-head {
  position: absolute;
  color: white;
  z-index: 100;
  bottom: 180px;
  left: 320px;
  font-weight: 900;
  font-size: 48px;
  font-family: Montserrat;
}
.carousel-head1 {
  position: absolute;
  color: white;
  z-index: 100;
  bottom: 100px;
  left: 380px;
  font-weight: 900;
  font-size: 48px;
  font-family: Montserrat;
}
.carousel-subhead {
  position: absolute;
  color: white;
  z-index: 100;
  bottom: 240px;
  left: 320px;
  font-weight: 300;
  font-size: 48px;
  font-family: Montserrat;
}
.carousel-content {
  position: absolute;
  color: white;
  z-index: 100;
  bottom: 540px;
  left: 320px;
  font-weight: 300;
  font-size: 48px;
}
.carousel .thumbs-wrapper {
  display: none !important;
}
.trending-thread-content {
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  height: 16vh;
  max-width: 90vh;
}
.trending-thread p {
  line-height: 20px;
  height: 6vh;
}
.forum-trending-content p {
  color: white !important;
}
.forum-trending-content p a strong {
  color: #fc5c2d;
}
.forumcontentone-image p h4 strong {
  color: white;
  font-size: 14px;
}
.forum-home-thread p {
  line-height: 4vh !important;
  font-size: 1.4vh !important;
}
.trending-thread p {
  font-size: 1.6vh;
  text-overflow: normal;
  line-height: 5vh;
}

.forum-trending-content h2 strong {
  color: white;
  font-size: 13px;
}
.bottom-nav-forum-pop {
  display: flex;
  flex-direction: row;
}
.forum-navbar-btn-bottom {
  padding-top: 2vh !important;
  padding: 1vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.forum-navbar-btn-bottom p {
  font-size: 10px;
  text-align: center;
  color: white;
  width: 7vh;
}
/* .ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  position: fixed !important;
  top: unset !important;
  bottom: 7vh !important;
} */
.forum-home-thread-square-textbox p p a strong {
  color: #fc5c2d;
}
.forum-home-thread-square-textbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.forum-timing {
  text-align: right;
}
.react-tel-input input[type="text"],
.react-tel-input input[type="tel"] {
  background: rgba(255, 255, 255, 0.08) !important;
  border: none !important;
  box-shadow: unset !important;
}
.react-tel-input {
  background: rgba(255, 255, 255, 0.08) !important;
}
.react-tel-input .selected-flag {
  background: rgba(255, 255, 255, 0.08) !important;
  border: none !important;
}
.react-tel-input .flag-dropdown {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border: none !important;
}
.detail_wrap {
  display: flex;
  flex-direction: column;
  padding-right: 34px;
  height: auto;
}
.service_main_wrap {
  min-height: 100vh;
}

.carousel .carousel-status {
  display: none;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .cart_content {
    flex-direction: column;
  }
  .nearby_sos {
    width: unset !important;
  }
  .map-sos h3 {
    padding-top: unset;
  }
  .service_main_wrap {
    min-height: 100vh;
    margin-left: 0;
  }
  .detail_wrap {
    width: 100% !important;
    padding-left: 34px;
  }
  .cart_items_wrap {
    padding-top: unset !important;
  }
  .summry {
    margin-top: 24px;
  }
  .booking_summary {
    width: unset !important;
  }
  .recom-acc {
    display: block;
  }
  .recom-se {
    display: block;
  }
  .content-home {
    padding-top: 0;
  }
  .carousel .slider-wrapper {
    height: 40vh;
  }
  .carousel .slide .testcar {
    bottom: unset !important;
  }
  .trending-thread-content {
    width: 27vh !important;
    padding-right: 2vh;
    height: 20vh;
  }
  .trending-thread-content h6 {
    white-space: pre-line;
    text-overflow: ellipsis;
  }
  .testcar {
    width: 80vw !important;
  }
  .head-icons {
    margin-bottom: 1vh;
    padding-top: 44px !important;
  }

  .header {
    width: 100% !important;
    max-width: 100vw;
    padding-right: 1em;
    display: none;
  }
  .headerstore-secondlogo {
    width: 20em;
    margin-right: 2vh;
  }
  .home-thread a {
    margin-right: 2vh;
  }
  a.skip-btn {
  }
  .signin-logos-line {
    justify-content: center !important;
  }
  .signin-logo-head {
    height: 14vh !important;
  }
  .header-wrap {
    display: flex;
    /* justify-content: center;
    align-items:center; */
  }
  .breadcrumb {
    margin-left: 1em;
  }
  .carousel-subhead {
    font-size: 25px !important;
    bottom: 80px;
    left: 100px;
  }
  .el-head {
    left: 80px;
    bottom: 50px;
    font-size: 25px;
    font-family: Montserrat;
  }
  .carousel-head1 {
    font-size: 25px;
    bottom: 70px;
    left: 100px;
  }

  .carousel.carousel-slider {
    height: 40vh;
    width: 100% !important;
  }
  .carousel .control-dots {
    padding-right: 6em !important;
  }
  .carousel .slide img {
    width: 100%;
  }

  .testcar h3 {
    font-size: 17px;
    font-weight: bold;
  }
  .testcar p {
    font-size: 9px !important;
  }
  .newnav {
    display: none;
  }
  .recc-pro {
    flex-direction: column;
  }
  .products-individual {
    width: 100%;
    margin-bottom: 1em;
  }
  .home-content {
    background-image: unset;
  }
  .banner {
    padding: 0;
    padding-top: 7em;
    margin-left: 0 !important;
  }

  .content-home {
    height: auto;
  }
  .home-icons {
    padding-bottom: 4em !important;
  }
  .home-icons-wrap {
    padding: 0;
    /* background-image: url("./vector-icons/hexagon.png"); */
    background-repeat: repeat-y;
    background-size: contain;
  }
  .home-second-content-wrap {
    flex-direction: column;
    /* align-items: center; */
  }
  .home-second {
    /* justify-content: center;
    align-items: center; */
    padding: 0;
    margin: 3em 1em 2em 1em;
  }
  .home-second-part1 h1 {
    font-size: 64px !important;
  }
  .home-second-content {
    width: 100%;
    justify-content: space-around;
  }
  .home-second-second {
    width: 100%;
    justify-content: space-around;
  }
  .head-icons {
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 3em;
    justify-content: space-around !important;
  }
  .head-icons img {
    width: 22vh !important;
  }
  .home-content-wrap h5 {
    padding-left: 1.5em !important;
  }
  .home-content-part1,
  .home-content-part2 {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    background-repeat: unset;
    background-repeat: repeat-y;
    justify-content: space-around;
    /* background-image: url("./vector-icons/hexagon.png"); */
    padding: 14px;
  }
  .inivitation_card span h1 {
    font-size: 24px !important;
  }
  .home-content h5 {
    padding-top: 8.99vh;
  }
  .home-content-part1 img .home-content-part2 img {
    width: 10em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .home-accessories {
    width: 12em;
    height: 12em;
    margin-left: 0;
  }
  .home-accessories img {
    width: 5em;
  }
  .home-content-wrap {
    padding-bottom: 8.99vh;
    margin: 0;
  }
  .home-icons-wrap h5 {
    padding-left: 1.5em !important  ;
  }
  .home-content-wrap h5 {
    padding-left: 1.5em;
  }
  .home-facebook-content {
    padding: 1em;
  }
  .home-facebook {
    padding-bottom: 3em;
  }

  .home-icons {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    height: 40vh;
  }

  .news-home2 {
    padding: 0;
  }
  .news-home {
    padding: 0;
    padding-bottom: 3em;
  }
}

.signin-logo-head {
  height: 15vh;
}
.headerstore-firstlogo {
  margin-left: 54px !important;
}
.headerstore-firstlogo1 {
  margin-left: 54px !important;
  width: 120px;
}
.ant-notification-notice {
  z-index: 100050 !important;
}

/* STYLES FOR NEW NAVBAR AND HEADER */

.newnav {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.08),
    rgba(255, 255, 255, 0.08)
  );
  text-align: center;
  z-index: 1000 !important;
}

.ant-select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border: none !important;
  border-radius: 12px !important;
  height: 58px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  color: white !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: white !important;
  /* padding-top: 6px !important; */
  padding-left: 15px !important;
}
.ant-select {
  /* width: 60vh !important; */
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  color: white;
}

.navmenu svg {
  font-size: 30px;
  color: rgb(129, 127, 127);
  margin-top: 1em;
}
.newnav ul {
  list-style-type: none;
  margin: 0;
  width: 10vh;
  padding: 0;
  background: linear-gradient(0deg, rgb(34, 34, 34), rgb(36, 36, 36));
  position: fixed;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding-top: 3vh;
}
.trending-card-sub h5 p {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  white-space: wrap !important;
}
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  background: black;
  right: -50px !important;
}
[dir="ltr"]
  .ck.ck-dropdown
  .ck-button.ck-dropdown__button:not(.ck-button_with-text) {
  display: none;
}

.newnav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.newnav ul,
.ant-popover-content {
  margin-left: -0.5vh !important;
}
.newnav ul,
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 0 !important;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}
.newnav ul .ant-popover {
  left: 0 !important;
}

.ant-popover-title {
  display: none !important;
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}
.newnav > ul > li > .ant-popover {
  left: 107px !important;
}
.newnav ul a {
  width: 100%;
  height: 56px;
}
.newnav ul li {
  width: 100% !important;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newnav ul li:hover {
  background: rgba(255, 255, 255, 0.08);
}
.newnav ul li svg {
  font-size: 20px;
}
.navbar-active-now {
  background: rgba(255, 255, 255, 0.08);
  color: #fc5c2d;
  width: 86px;
  height: 56px;
}
.forum-navbar-btn {
  width: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 66px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.forum-navbar-btn p {
  font-weight: 600;
  font-size: 1.8vh;
  margin-bottom: 0;
}
.forum-navbar-btntwo {
  width: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 66px;
  cursor: pointer;
}
.forum-navbar-btntwo p {
  font-weight: 600;
  font-size: 1.8vh;
  margin-bottom: 0;
}
.navbar-active-now svg {
  color: #fc5c2d !important;
}
.navbar-active-now:hover {
  color: rgb(179, 176, 176);
  cursor: pointer;
}
.active svg {
  color: rgba(240, 92, 45, 0.87) !important;
}
.rs .active svg {
  color: white !important;
}
.accessories-moto .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.38);
  margin-right: 25vh;
  margin-left: 25vh;
}
.accessories-moto .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-size: 20px;
  font-weight: 500;
}
headerstore-secondlogo {
  width: 14vh !important;
}
.headerstore {
  position: fixed;
  z-index: 1000 !important;
  height: 99px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background: #252525 !important;
}
.ant-btn.ant-btn-primary {
  width: 21vh;
}
.headerstore-bg {
  width: 100%;
  background: #252525 !important;
}

.headerstore-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 3em 0 3em;
  padding-left: 0;
  height: 100%;
}
.headerstore-content img {
  width: 120px;
  height: 10vh;
}
.trending-home-profile {
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #fc5c2d;
}
.trending-image-home {
  width: 20vh;
  border-radius: 8px;
  object-fit: cover;
}
.ant-select-multiple .ant-select-selection-item-content {
  color: #fc5c2d !important;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .ant-modal {
    max-width: unset !important;
    width: 300px !important;
  }
  .headerstore {
    height: 58px !important;
  }
  .headerstore-firstlogo {
    margin-left: 0 !important;
    display: none;
  }
  .rewards_wrap {
    width: 1005 !important;
  }
  .service_content {
    flex-direction: column;
  }
  .service_caption {
    margin-bottom: 0 !important;
  }
  .service_explore {
    top: 55vh !important;
  }
  .product_explore {
    top: 122vh !important;
  }
  .service_container {
    width: 100% !important;
  }
  .service_container img {
    width: 100% !important;
  }

  .trending-image-home {
    width: 17vh;
  }
  .accessories-moto .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 15px;
    font-weight: 500;
  }
  .headerstore-content {
    padding: 0 0 0 0;
  }

  .accessories-moto .nav-tabs {
    margin-left: 0;
    margin-right: 0;
  }
  .accessories-moto .nav-tabs .nav-item {
    font-size: 14px;
  }
  .acc-main h2 {
    font-size: 24px;
  }
  .acc-main p {
    font-size: 12px;
  }
  .accessories-moto .nav-tabs .nav-link:hover {
    border: none;
  }
  .acc-main {
    min-height: 40vh;
    padding: 2em;
    margin-bottom: 3em;
  }
}
.accessories-moto {
  position: relative;
}

/* STYLES FOR NEW NAVBAR */

/* STYLES FOR NEW STEAM CLEANING */
.steam-cleaning-wrap {
  width: 100%;
}
.steam-wrap {
  padding-left: 5em;
}
.steam-clean-content {
  height: 600vh;
  background-image: url("./vector-icons/main-page-lines.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: 87vw auto;
  position: relative;
  display: inline-block;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: black !important;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  background: rgba(255, 255, 255, 0.08);
}
.forum-create-tag .ant-select {
  width: 50% !important;
}
.home-content-part1-1 {
  padding-top: 7em;
  padding-left: 5em;
  padding-right: 4.5em;
  display: inline-block;
  justify-content: space-evenly;
  /* background-image:url("./vector-icons/hexagon.png") ;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
  padding-top: 18em;
}
.home-content-part1-1 img {
  width: 13em;
  padding: 1em;
}
.home-content2 {
  width: 100% !important;
  height: 148vh;
  background-repeat: no-repeat;
  background-size: 90vw 290vh;
  position: relative;
}

/* STYLES FOR BOTTOMNAV */
.bottommenu {
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  display: none;
  z-index: 1000;
}
.bottommenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.bottommenu li {
  float: left;
}

.bottommenu li button {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
  padding-bottom: 2px;
}
.bottommenu li button img {
  width: 24px !important;
  height: 24px !important;
}

.bottommenu li button:hover {
  background-color: #111;
}
.bottommenu ul li svg {
  /* color:rgba(240, 92, 45, 0.87); */
  font-size: 23px;
  color: rgba(255, 255, 255, 0.87);
}
.bottom_bar {
  padding: unset !important;
}
.bottom_bar p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;

  color: #ffffff;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .height-define {
    height: auto;
  }
  .acc-main {
    width: 100% !important;
  }
  .bottommenu {
    display: flex;
  }
}

/* ////////////////////////////////////////HEADER/////////////////////////////////// */

/* ////////////////////////////////////PAYMENT//////////////////////////////////// */

.payment-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 6em;
  padding-top: 4em;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .payment-content {
    flex-direction: column !important;
    padding: 1em 1em;
  }
  .payment-method,
  .payment-objects {
    width: 100% !important;
  }
  .payment-method img {
    width: 23em !important;
  }
  .card {
    width: 40vw !important  ;
  }
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 5em;
  }
  .cart {
    padding-left: 0 !important;
  }
  .collection p {
    display: none !important;
  }
  .height-define {
    padding: 0 !important;
    padding-top: 4em !important;
  }
  .box {
    flex-direction: row;
  }
  .ant-comment-avatar img {
    width: 4vh !important;
    height: 4vh !important;
    overflow: hidden;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:active {
  color: white !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus {
  color: white !important;
}
.ant-tabs-tab-btn:focus {
  color: white !important;
}
.ant-comment-avatar img {
  width: 4vh !important;
  height: 4vh !important;
  overflow: hidden;
  object-fit: cover;
}
.forum-likebtn {
  background: transparent;
  color: rgba(255, 255, 255, 0.38);
  border: none;
  font-weight: 600;
}
.ant-list-empty-text {
  display: none;
}
.forum-like-n-reply {
  font-weight: 600;
}
.forum-likebtn-liked {
  color: #fc5c2d !important;
  background: transparent;
  border: none;
  font-weight: 600;
}
.like-button-forum {
  font-size: 20px !important;
}

.ant-list-split .ant-list-header {
  background: rgba(255, 255, 255, 0.08);
  border-bottom: none !important;
  padding: 1vh;
  margin-bottom: 2vh;
  padding-left: 9vh;
  padding-right: 11vh;
}
.ant-comment-content-detail {
  color: rgba(255, 255, 255, 0.6) !important;
}
.payment-method {
  width: 50%;
  display: flex;
  justify-content: center;
}
.payment-objects {
  width: 50%;
  display: flex;
  justify-content: center;
}
button.StripeCheckout {
  width: 10em !important;
  background: #ff6f00 !important;
}
.payment-estimate {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.StripeCheckout span {
  background-color: unset;
}

/* //////////////////////////////////////////FORUM//////////////////////////////////////////////////// */

.forum-home {
  min-height: 100vh;
  /* background-image: url("./vector-icons/hexagon.png"); */
  background-repeat: no-repeat;
  background-size: 63vw 112vh;
  background-color: #121212;
  padding-left: 8vh;
  padding-right: 8vh;
  overflow: hidden;
}
.forum-home-thread img {
  height: 22vh;
  width: 30vh !important;
  object-fit: cover;
}
.forum-home-thread {
  width: 100% !important;
}
.forum-home-thread p {
  height: 18vh;
  overflow: hidden;
  line-height: 30px;
}
.ant-picker-dropdown {
  z-index: 100 !important;
}

.forum-more-container {
  position: absolute;
  right: 0;
}
.threadexpand-content h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fc5c2e;
}
.threadexpand-content h2 {
  color: white;
  font-size: 17px;
}
.carousel p h2 strong {
  font-size: 14px !important;
  color: white;
}
.forum-home-thread p h2 strong {
  color: white;
  font-size: 1.4vh;
}
.forum-home-thread p p a strong {
  color: #fc5c2d;
}
.carousel p {
  overflow: hidden;
  text-overflow: ellipsis;
}
.carousel p a strong {
  color: #f05c2d;
}
.forum-home-thread-square-textbox h6 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.forum-home-thread-square-textbox p h2 strong {
  font-size: 13px;
  color: white;
}
.trending-card-sub h5 h2 strong {
  color: white;
  font-size: 10px;
  margin-bottom: 0;
}
.trending-card-sub h5 a strong {
  color: #fc5c2d;
}
.threadexpand-content p a strong {
  color: #fc5c2d;
}
.threadexpand-content h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: justify;
  padding-right: 1vh;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) {
  .home-wrap {
    flex-direction: column !important;
  }
  .forum-threads {
    padding: 0 !important;
    padding-bottom: 0 !important;
  }
  .ant-space-align-center {
    margin-top: 10vh;
  }
  .forum-more-container {
    top: 15vh;
    right: 0vh;
  }
  .ant-popover-inner {
    height: auto !important;
  }
  .ant-picker-panel-container {
    margin-left: 2vh !important;
  }
  .forum-home {
    padding-top: 16vh;
  }
  .forum-home-thread {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }
  .forum-home-thread p {
    height: 9vh;
    line-height: 3.6vh;
  }
  .forum-home-thread h5 {
    font-size: 15px !important;
  }
  .forum-home-thread img {
    height: 16vh !important;
    width: 19vh !important;
  }
  .price {
    margin-bottom: 6vh;
  }
  .addtocart {
    font-size: 15px !important;
  }
  .rs {
    width: 60% !important;
  }
  .rs h3 {
    font-size: 20px;
  }
  .cost {
    font-size: 15px;
  }
  .forum-home {
    background-size: contain;
    padding-bottom: 7em;
    padding-right: unset;
    padding-left: unset;
  }
  .payment-content {
    padding-top: 4em !important;
  }
  .payment-method {
    padding-bottom: 4em;
  }
  .threadlist {
    padding: 0 !important;
    padding-top: 8em !important;
  }
  .threadlist .forum-threadtwo {
    padding-top: 0 !important;
  }
  .threadlist-head {
    padding-left: 1.3em !important;
  }
}

.threadlist {
  background-color: #121212;
}
.forum-home-firstpart {
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
  position: relative;
}
.seeall-forum {
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
  color: #f05c2d !important;
  font-family: Montserrat;
  font-weight: 600;
}
.forum-home-service {
  justify-content: center;
  align-items: center;
}
.forum-service-now {
  width: 18.37vh;
  height: 6.22vh;
  background: #ff6f00;
  color: black;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  font-weight: bold;
  font-family: Montserrat;
}
.forum-service-now:hover {
  box-shadow: 0px 15px 25px -5px black;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  color: black !important;
}
.forum-service-now:active {
  box-shadow: 0px 4px 8px black;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}
.forum-home-thread {
  display: flex;
  flex-direction: row;
  margin-top: 3em;
  width: 60%;
  border-radius: 8px;
  padding: 1em;
}
.forum-home-thread:hover {
  background-color: #1b1b1b;
}

.forum-more {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.forum-more-share {
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: solid 1px #fc5c2d;
}
.forum-threadtwo {
  justify-content: space-evenly;
  align-items: center;
  border-top: 2px solid rgba(255, 255, 255, 0.08);
  padding-bottom: 4.99vh;
  padding-top: 3em;
}
.forum-home-thread-square {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
}
.forum-home-thread-square:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  cursor: pointer !important;
}

.content-home .forum-threadtwo {
  border-top: unset !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.home-thread {
  border-bottom: 1px solid #1e1e1e;
  color: #f05c2d !important;
  margin: 2vh !important;
  margin-bottom: 0 !important;
  margin-top: 8.99vh !important;
  display: flex;
  justify-content: space-between;
}
.ant-comment-actions > li > span,
a:not([href]):not([tabindex]) {
  color: #ffb04c !important;
}
.home-thread a {
  color: #fc5c2d !important;
  font-weight: 600;
  font-family: Montserrat;
}
.home-thread h6 {
  color: white !important;
  font-family: Montserrat;
  font-weight: bold;
}

.forum-home-thread img {
  width: 15em;
  border-radius: 8px;
  object-fit: cover;
}
.forum-home-thread {
  margin-top: 0 !important;
}
.forum-home-thread-square {
  border-radius: 8px;
  background-color: #1b1b1b;
  min-width: 30vh;
  width: 45vh;
  min-height: 42vh;
  height: 44vh;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 14vh;
  margin-left: 4vh;
}
.forum-home-thread-square2 {
  border-radius: 8px;
  background-color: #1b1b1b;
  min-width: 30vh;
  width: 38vh;
  min-height: 42vh;
  height: 44vh;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 4vh;
}
.forum-home-thread-square-textbox p {
  font-size: 1.7vh;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.forum-home-thread-square2 img {
  max-width: 45vh;
  border-radius: 8px 0 0 0;
  min-height: 30vh;
  width: 38vh;
  height: 21vh;
  object-fit: cover;
}
.forum-home-thread-square img {
  max-width: 100%;
  width: 60vh;
  border-radius: 8px 0 0 0;
  min-height: 30vh;
  height: 30vh;
  object-fit: cover;
}
.trending-thread {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
  justify-content: space-between;
  padding: 2em 0;
  cursor: pointer;
  padding: 1em;
}
.forum-thread-create .ant-btn {
  background: #ff6f00;
  color: black !important;
  border-radius: 8px !important;
  font-weight: 900 !important;
}
.trending-thread:hover {
  background-color: #1b1b1b;
  border-radius: 8px;
}

.clubhomemain {
  position: relative;
}
.clubhome-posters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3em;
  padding-bottom: 5em;
}
.clubhome-posters img {
  width: 15em !important;
  border-radius: 8px;
}
.forum-create {
  position: absolute;
  right: 6em;
  border: none;
  background: #ff6f00;
  font-family: Montserrat;
  height: 3em;
  border-radius: 8px;
  width: 14em;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #000000;
}
.continue {
  border: none;
  width: 10em;
  text-decoration: underline;
  background: transparent;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.ant-popover-inner {
  height: auto !important;
  background: #383838 !important;
  color: white !important;
  box-shadow: 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ant-popover-inner-content {
  height: 6vh;
}
.ant-popover-inner-content button:hover {
  border-radius: 8px !important;
}

.forum-video iframe {
  border-radius: 8px;
  height: 45vh;
}
.uploaded-image-forum {
  width: 14em !important;
  border-radius: 8px;
  margin-bottom: 4vh;
  margin-left: 2vh;
}
.edit-mail-sbmt {
  border: none;
  height: 5vh;
  width: 10vh;
  color: white !important;
  background-color: #333333;
  padding-left: 1em;
}
.edit-mail-sbmt:active {
  background-color: #241814;
}
.uploaded-image-forum-multi {
  width: 15em !important;
  border-radius: 8px;
  margin-bottom: 4vh;
}
.forum-video {
  height: auto !important;
  object-fit: cover !important;
  width: 83vh !important;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: #f05c2d !important;
  border: none !important;
  cursor: pointer;
}
.tag-pop-wrap {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: left;
  width: 67%;
}

.tag-pop {
  background: rgba(255, 255, 255, 0.08);
  border: none;
  color: #f05c2d;
  border-radius: 16px;
  font-size: 2vh;
  padding: 2vh 3vh;
  box-sizing: border-box;
  font-weight: 600;
  overflow: hidden;
  width: 20vh;
}
.ant-popover-title {
  border-bottom: unset !important;
}
.ant-popover-inner-content a {
  color: white !important;
  font-size: 15px;
}
.forum-more:hover {
  color: #e4e4e4;
}
.forumcontentone {
  position: relative;
}
.cover_login {
  position: relative;
  padding-bottom: 15vh;
}
.close-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 12px !important;
  padding-top: 12px;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .forum-home-thread-square2 img {
    width: 42vh !important;
    max-width: 50vh;
  }
  .vehicle_wrap_wrap {
    flex-wrap: wrap;
    padding-bottom: 54px;
  }
  .vehicle_wrap_wrap div {
    margin-top: 24px;
  }
  .forum-banner-text h3 {
    font-size: 2vh !important;
  }
  .signupform .cover_login {
    position: relative;
    padding-bottom: 15vh;
  }
  .close-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 12px !important;
    padding-top: 12px;
  }
  .tag-pop-wrap {
    margin-top: 2vh;
    margin-bottom: 2vh;
    justify-content: center;
    align-items: center;
  }
  .forum-more {
    display: flex !important;
  }
  .forum-more-share {
    display: flex;
  }
  .ant-comment-actions > li > span,
  a:not([href]):not([tabindex]) {
    font-size: 10px;
    padding-right: 1em;
  }
  .forum-video {
    width: 100% !important;
    height: 100% !important;
  }
  .content-home .forum-threadtwo {
    margin: 0 !important;
  }
  .clubhome-posters {
    flex-direction: column;
    align-items: center;
  }
  .forum-home-thread-square img {
    width: 24em !important;
    max-width: 25em !important;
  }
  .home-thread {
    margin-bottom: 0 !important;
    margin-left: 1em !important;
    margin-right: 0 !important;
    margin-top: 2vh !important;
  }
  .trending-card-sub h5 h2 strong {
    font-size: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }
  .trending-card-sub h5 h2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  .forum-threadtwo {
    padding-top: 0 !important;
    margin: 2vh !important;
    flex-direction: column !important;
    padding: 1em 1em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .expand-mobile-share-btn {
    display: inline-block !important;
  }
  .ant-modal-close-x {
    display: none !important;
  }

  .forum-home-link-forum {
    padding-top: 0 !important;
  }
  .forum-home-thread-square {
    width: 24em;
    margin-top: 1em;
    min-height: unset;
  }
  .forum-home-thread img {
    width: 50vw;
    height: 15vh;
  }
  .forum-home-thread p {
    font-size: 10px;
  }
  .forum-home-thread h6 {
    font-size: 15px;
  }
  .forumcontentone {
    padding: unset !important;
  }
  .forum-home-thread {
    width: unset !important;
  }
  .forum-head-image {
    width: 100% !important;
  }

  .forum-head-image img {
    width: 87vw !important;
  }
  .thread-share {
    display: none !important;
  }
  .threadexpand {
    flex-direction: column !important;
  }
  .thread-expanded-trending {
    position: unset !important;
    padding: 2vh !important;
    margin-bottom: 3vh;
    margin-left: 0 !important;
    width: unset !important;
    height: unset !important;
    overflow: unset !important;
    background-color: #121212;
  }

  .forum-home-firstpart {
    flex-direction: column !important;
    padding: 0 !important;
    padding-top: 5em !important;
    padding-bottom: 5em !important;
  }
  .clubhomemain {
    padding: unset !important;
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 375px !important;
  }

  .timeline-content-images {
    flex-direction: column !important;
  }
  .timeline-account-detail {
    width: unset !important;
  }
  .forum-service-now {
    width: 10em;
  }
  .threadexpand-content {
    padding: 2vh !important;
  }
  .thread-expanded-image {
    width: 42vh !important;
  }
  .threadexpand-content h5 {
    text-align: unset;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon,
  .anticon svg {
    font-size: 12px !important ;
  }
  .commenting-forum {
    width: 90% !important;
  }
  .seeall-forum {
    padding-top: 3em;
  }
  .forum-threads {
    padding: 1em !important;
    margin-bottom: 8.99vh !important;
  }
  .trending-thread {
    height: 20vh;
  }
  .trending-thread p {
    font-size: 1.3vh !important;
    height: unset !important;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 6.7vh;
  }
  .trending-thread h6 {
    font-size: 15px !important;
  }
  .forum-home .forum-threadtwo {
    margin-bottom: 10vh;
  }
  .seeall-forum {
    bottom: -4em;
    right: 1em;
  }
}

/* ////////////THREAD EXPANDED////////////////////// */
.threadexpand {
  padding: 7em;
  padding-right: 6vh;
  background: #141414;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.thread-expanded-image {
  width: 100%;
  margin-bottom: 4vh;
}
.thread-share {
  position: fixed;
  margin-top: 10vh;
  width: 15.74vh;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid rgba(238, 238, 238, 0.08);
}
.thread-modal,
.ant-modal-footer,
.ant-modal-close-x,
.expand-mobile-share-btn {
  display: none;
}

.thread-share div {
  height: 4vh;
  width: 4vh;
  border: 1px solid #fc734b;
  border-radius: 50%;
  cursor: pointer;
}
.thread-expanded-trending {
  overflow: scroll;
  position: -webkit-sticky;
  position: sticky;
  top: 8vh;
  width: 46vh;
  height: 90vh;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-left: 8vh;
  padding-top: 8vh;
  z-index: 10;
}
.categories {
  overflow: scroll;
  position: -webkit-sticky;
  position: sticky;
  top: 8vh;
  width: 46vh;
  height: 90vh;
  left: 0;
  display: flex;
  flex-direction: column;
  margin-left: 8vh;
  padding-top: 8vh;
  z-index: 10;
}
.thread-expanded-trending2 {
  top: 8vh;
  width: 46vh;
  height: 90vh;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-left: 8vh;
  z-index: 10;
}
.forum-banner-text h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: justify;
  word-spacing: -2px;
}
.thread-expanded-trending2 h4 {
  font-size: 2vh;
}
.forum-home-threads {
  z-index: 0;
}
.thread-expanded-trending::-webkit-datetime-edit-year-field:not([aria-valuenow]),
.thread-expanded-trending::-webkit-datetime-edit-month-field:not([aria-valuenow]),
.thread-expanded-trending::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
/* html {
  overflow: auto;
} */

.thread-expanded-trending::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.thread-expanded-trending::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 transparent;
  -webkit-border-radius: 0px;
  border-radius: 0;
}
.ant-col-12 {
  max-width: unset !important;
}

.thread-expanded-trending::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: transparent;
}
.thread-expanded-trending::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}
.thread-expanded-trending h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.trending-card {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 4vh !important;
  height: 15vh;
}
.trending-card:hover {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
}

.trending-card img {
  width: 7.5vw;
  height: 15vh;
  object-fit: cover;
  border-radius: 8px;
}
.ant-btn-primary {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  height: 10px;
  width: 15vh;
  outline: 0;
  font-family: Montserrat;
  box-shadow: 0px 5px 10px black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: white !important;
  border-radius: 8px !important;
  background: #ff6f00 !important;
}
.trending-card h5 {
  font-weight: 600;
  font-size: 1.73vh;
  line-height: 12px;
}
.trending-card-sub {
  margin-left: 2.6vh;
  white-space: wrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}
.trending-card-sub h5 {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: white;
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .react-share__ShareButton{
  width: 20vh!important;
  height: 20vh!important;

} */

.trending-card-sub h4 {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.trending-card-sub h5 h2 strong {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 80vw !important;
    height: auto !important;
  }
}
.testcar p {
  height: 6vh !important;
  font-size: 1.6vh;
}
.banner-image {
  height: auto;
  width: 100%;
  max-height: 40vh;
  object-fit: cover;
}
.banner-image-forum {
  height: auto;
  width: 20vh;
  max-height: 60vh;
  object-fit: cover;
  min-height: 60vh;
}
.forum-banner-cover {
  width: 20%;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  height: 60vh;
  min-width: 30%;
}
.forum-banner-text {
  height: 100%;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 4vh;
  padding-top: 72px;
}
.forum-banner-text h3 {
  color: white;
  font-size: 30px;
}
.home-banner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.forum-banner {
  display: flex;
  flex-direction: row;
}
.home-banner-image-cover {
  position: relative !important;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #fc5c2d;
}
.carousel .control-dots .dot {
  opacity: 1 !important;
}
.home-banner-image-cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: inline-block;
  object-fit: cover;
}
.home-banner-image-cover img {
  height: 100%;
  border-radius: 8px 8px 0 0 !important;
  object-fit: cover;
}

/* .ant-comment-content-author{
  display: flex!important;
  justify-content: space-b!important;
} */
.ant-btn > .anticon + span,
.ant-btn > span + .anticon,
.anticon svg {
  font-size: 14px !important ;
}
.ant-comment {
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
}
.ant-list-items .ant-comment {
  padding-left: 0 !important;
}

.item-desc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.forum-trending-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.commenting-forum {
  width: 100%;
}
.ant-drawer-header {
  background: #383838 !important;
  border-bottom: unset !important;
  box-shadow: rgba(255, 255, 255, 0.267);
}
.ant-drawer {
  z-index: 1000010 !important;
}
.ant-form-item {
  color: black !important;
}
.ant-drawer-body,
.ant-drawer-footer {
  background: #333333 !important;
  border-top: unset !important;
}
.ant-drawer-title,
.ant-form-item .ant-form-item-label > label {
  color: white !important;
}
.ant-form-item-label {
  text-align: left !important;
}
.thread-create-textfield textarea {
  border-radius: 8px;
}
.thread-create {
  background: #121212;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .testcar p {
    display: none;
  }
  .table {
    flex-direction: column-reverse;
    align-items: center;
    width: unset !important;
  }
  .service_list_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10vh 10vw;
    padding-left: 10vw !important;
  }
  .service_list_part1 h1 {
    font-size: 24px !important;
  }
  .testcar h3 {
    font-size: 2vh;
    padding-top: 1vh;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    margin-left: 0 !important;
  }
  .tags_cover {
    margin-left: unset !important;
  }
  .forum-home-second-div {
    display: flex;
    flex-direction: column !important;
  }
  .slick-prev:before,
  .slick-next:before {
    display: none !important;
  }
  .thread-expanded-trending2 {
    margin-left: 2vh !important;
    margin-top: 2vh;
  }
  .forum-home-thread-square {
    margin-left: 0 !important;
    height: 45vh;
  }

  .thread-expand-gallery {
    height: 230px !important;
    margin-right: 10px !important;
  }
  .home-content {
    padding: 0 !important;
  }
  .home-content-part1,
  .home-content-part2 {
    padding: 0 !important;
  }
  .explore-link {
    padding-top: 3vh;
  }
  .home-content-part1 img,
  .home-content-part2 img {
    width: 24vh !important;
    height: 24vh !important;
    padding-top: 5vh;
    object-fit: cover;
  }
  .css-f12czy {
    height: 20em !important;
  }
  .thread-create {
    padding: 2vh !important;
    padding-bottom: 10vh !important;
    padding-top: 14vh !important;
  }
  .threadexpand-wrap {
    width: unset !important;
    margin-left: 0 !important;
  }
  .commenting-forum .ant-comment {
    width: unset !important;
  }
  .ant-spin-nested-loading {
    padding-left: unset !important;
  }
  .ant-comment {
    padding-left: unset !important;
  }
  .commenting-forum {
    width: 100% !important;
  }
  .ant-comment-inner {
    padding-right: 3vh !important;
    padding-left: 2vh !important;
  }
  .ant-list-split .ant-list-header {
    padding-left: 3vh;
    padding-right: 2vh;
  }
  .ant-list-items .ant-comment {
    padding-left: 1em !important;
  }
  .thread-create-field {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    padding-top: 0 !important;
  }
  .thread-create-imagefield {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    justify-self: space-between !important;
  }
  .thread-create-upload {
    width: 50% !important;
  }
  .thread-create-title {
    width: 100% !important;
  }
  .thread-create-textfield {
    width: 100% !important;
  }
  .thread-create-content {
    width: 100% !important;
  }
  .threadexpand {
    padding: 0;
    padding-bottom: 5em;
  }
  .threadexpand-content {
    justify-content: center;
    align-items: left !important;
    width: unset !important;
    padding-top: 8em !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
  .mobile-signout {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-forum-threads {
    margin-top: 0 !important;
  }
}
.thread-expand-gallery {
  width: 618px !important;
  height: 286px !important;
  margin-right: 15px;
  object-fit: cover !important;
}
.threadexpand-content {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 2em;
  font-family: Montserrat;
  justify-content: space-between;
}
.thread-expand-time-n-more {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.threadexpand-wrap {
  width: 115vh;
  border-radius: 8px;
  margin-left: 14%;
}
.threadexpand-content p {
  font-size: 13px;
  font-weight: normal;
  padding-top: 1em;
  text-align: justify;
}
.threadexpand-content h5 {
  font-size: 21px;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
}
.threadexpand-content img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  border-radius: 8px;
}
/* .ant-comment-actions > li > span,
a:not([href]):not([tabindex]) {
  color: white !important;
} */
.ant-comment-content-author-name {
  color: #f05c2d !important;
}
.ant-comment-content-detail {
  color: rgba(255, 255, 255, 0.8) !important;
}
/* .ant-btn.ant-btn-primary {
  background-color: rgba(255, 255, 255, 0.08) !important;
} */
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  background-color: rgba(255, 255, 255, 0.08);
  color: white;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input:hover {
  border-color: unset;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input:active {
  border-color: #fc5c2d;
}
.ant-list-header {
  color: rgba(255, 255, 255, 0.38) !important;
}
.ant-spin-nested-loading {
  width: 98%;
  padding-left: 3em;
}
.forum-like-n-reply {
  display: flex;
  justify-content: space-between !important;
}
.thread-profile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;
  margin-top: 1vh;
}
.thread-header-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.thread-profile-header img {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  object-fit: cover;
}

.thread-profile-header p {
  color: #fc5c2d;
  font-weight: 600;
  font-family: Montserrat;
  padding-left: 1em;
}

/* ////////////////////////////////////////ForumCreate/////////////////////////////////////////// */

/* .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #ff6f00 !important;
  color: rgba(255, 255, 255, 0.38) !important;
  border: none;
  border-left: none !important;
  border-color: unset !important;
  border-radius: 20px !important;
  margin: 2em;
  margin-left: 0 !important;
}
.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:last-child {
  border-radius: 20px !important;
  margin: 2em !important;
  margin-left: 0 !important;
}
.ant-radio-button-wrapper {
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: rgba(255, 255, 255, 0.38) !important;
  border-color: black !important;
  border-radius: 20px;
  margin: 2em !important;
  margin-left: 0 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset !important;
  background: unset !important;
  margin: 2em;
  margin-left: 0 !important;
}
.ant-radio-button-wrapper::before {
  background: unset !important;
} */
.create-forum-button {
  background: #ff6f00;
  border-radius: 8px;
  border: none;
  width: 15em;
  color: black;
  height: 7vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1.9vh;
  line-height: 16px;
}
.create-forum-button-cancel {
  background: transparent;
  border: none;
  width: 24vh;
  color: #fc5c2d;
  height: 6vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6vh;
  line-height: 16px;
}
.alt-img-btn {
  background: #ff6f00;
  border: 1px dashed rgba(255, 255, 255, 0.87);
  border-radius: 50%;
  width: 107px;
  height: 107px;
  border: none;
  /* width: 15em; */
  color: black;
  /* height: 7vh; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 1.7vh;
  cursor: pointer;
  text-align: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  line-height: 7vh;
  font-weight: 600;
  display: flex;
  justify-content: center;
  background: rgba(196, 196, 196, 0.16);
}
.alt-img-btn-upload {
  background: transparent;
  border-radius: 8px;
  border: none;
  width: 15em;
  color: #666;
  height: 7vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 1.7vh;
  cursor: pointer;
  text-align: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  line-height: 7vh;
  font-weight: 600;
}
.header-up-btn svg {
  font-size: 5vh;
  color: #666 !important;
}
.tab-cover-image > .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}
.tab-cover-image > .ant-tabs-tabpane {
  display: flex !important;
  justify-content: center !important;
}
.tab-cover-image > .ant-tabs-tabpane {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-forum-button:hover {
  box-shadow: 0px 15px 25px -5px black;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  color: black !important;
}
.create-forum-button:active {
  box-shadow: 0px 4px 8px black;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

/* ////////////////////////////////////CLUB HOME FORUM//////////////////////////////////////// */

.ant-timeline-item {
  padding-bottom: 15vh !important;
  color: white !important;
}

.ant-timeline-item-tail {
  border-color: rgba(255, 255, 255, 0.08) !important;
}
.ant-timeline-item-head-blue {
  border-color: #fc5c2e !important;
  border-width: 1px !important;
}
.timeline-image {
  width: 15em;
  border-radius: 8px;
}
.timeline-content {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  width: 80%;
  padding: 4em;
}
.timeline-content h6 {
  color: white !important;
  font-family: Montserrat;
}
.timeline-content-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.timeline-content-images img {
  width: 12em;
  border-radius: 8px;
}
.ant-timeline-item-content {
  margin-left: 50px !important;
  margin-top: 15px !important;
}
.timeline-account-detail {
  padding-top: 2em;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-picker {
  color: white !important;
}
/* .ant-picker-dropdown {
  width: 90% !important;
} */
.ant-picker-input > input {
  color: white !important;
}
.ant-spin-dot-item {
  background-color: #fc5c2d !important;
}
.carousel.carousel-slider {
  border-radius: 8px;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  margin-left: 0.2px !important;
}

.news > .carousel.carousel-slider {
  border-radius: 8px;
  width: 180vh !important;
}
.news-home > .carousel.carousel-slider {
  border-radius: 8px;
  width: 180vh !important;
}
.news-home2 > .carousel.carousel-slider {
  border-radius: 8px;
  width: 180vh !important;
}
.tags-forum-home {
  color: white;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212;
  border-radius: 30px;
  border: none;
  margin-left: 2vh;
  margin-top: 2vh;
  text-transform: capitalize;
  font-size: 1.5vh;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  width: auto;
  padding: 8px 12px;
}
.tags-forum-home:focus {
  background: rgba(255, 255, 255, 0.2);
  font-weight: 600;
  box-sizing: border-box;
  padding: 1vh 1vh;
}

.forum-create-tag {
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40%;
  border-radius: 8px;
}
.react-tags {
  display: flex;
  margin-bottom: 2em;
}
.react-tags__selected button {
  color: white !important;
  background-color: #333333;
  border: none;
  width: auto;
  height: 4vh;
  margin-left: 5px;
  font-size: 15px;
}
.react-tags__search-input {
  width: 111px !important;
  text-align: center;
  color: white !important;
  border: none;
  height: 4vh;
  background-color: #333333 !important;
  margin-left: 1em;
  font-size: 15px;
}
.react-tags__search {
  background-color: #333333 !important;
}

.react-tags__suggestions {
  background-color: #333333 !important;
  background: #333333 !important;
  visibility: visible !important;
  display: block;
  color: white;
  margin-top: 1em;
  width: auto;
  text-align: center;
}
.react-tags__suggestions ul {
  list-style-type: none;
  text-align: center;
  padding-right: 1em;
}
.mobile-signout {
  display: none;
  color: #fc5c2d !important;
  margin-top: 3vh;
  margin-bottom: 2vh;
}
.mobile-signout h6 {
  color: #fc5c2d !important;
  font-weight: bold;
}
.mobile-signout button {
  margin-top: 1vh;
  background: transparent;
  border: none;
  height: 5vh;
  width: 10vh;
  font-size: 20px;
  box-shadow: 0px 8px 15px 0px rgba(61, 61, 61, 0.1);
}
.mobile-signout button:active {
  font-size: 25px;
  box-shadow: 0px 8px 15px 0px rgba(126, 125, 125, 0.2);
}

.home-wrap {
  display: flex;
  flex-direction: row;
}
.home-forum-threads {
  margin-top: 7vh;
  background-color: #121212;
}
.home-forum-threads button {
  border: 1px solid #fc5c2d;
  background: transparent;
  color: white;
  height: 20vh;
}
.forum-threads {
  padding: 0 !important;
  padding-bottom: 15vh;
}
.rich-text-manage p,
.rich-text-manage li,
.rich-text-manage ul,
.rich-text-manage a,
.rich-text-manage h1,
.rich-text-manage h2,
.rich-text-manage h3,
.rich-text-manage h4,
.rich-text-manage h5,
.rich-text-manage h6,
.rich-text-manage td,
.rich-text-manage tr,
.rich-text-manage th {
  color: white !important;
}
.ck.ck-editor__editable_inline > :last-child {
  color: black;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background: #121212;
  border-color: rgba(255, 255, 255, 0.08) !important;
  border-width: 2px;
}
.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
  color: white;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  background: #121212;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border: none;
}
.ck.ck-toolbar {
  border: 2px solid rgba(255, 255, 255, 0.08) !important;
}
.ck.ck-toolbar > .ck-toolbar__items > *,
.ck.ck-toolbar > .ck.ck-toolbar__grouped-dropdown {
  color: white;
}
[dir="ltr"]
  .ck.ck-dropdown
  .ck-button.ck-dropdown__button:not(.ck-button_with-text) {
  color: white;
}
.thread-title {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px 4px 0px 0px;
  border: none;
  border-bottom: 1px solid #fc5c2e;
  width: 80%;
  height: 7vh;
  padding: 4vh;
  box-sizing: border-box;
  color: white;
}
.forum-n-sub select {
  background: rgba(255, 255, 255, 0.08);
  width: 451px;
  height: 48px;
  color: white;
  box-sizing: border-box;
  padding-left: 2em;
  border: none;
  border-bottom: 1px solid #fc5c2e;
}
.forum-n-sub select option {
  color: black;
}
.thread-title-sml {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px 4px 0px 0px;
  border: none;
  border-bottom: 1px solid #fc5c2e;
  height: 7vh;
  width: 70vh;
  padding: 3vh;
  box-sizing: border-box;
  color: white;
}
.ck.ck-editor {
  width: 1018px !important;
}
.thread-create-rich {
  background-color: #121212;
}
.thread-create-rich h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
}
.forum-n-sub {
  width: 1018px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.thread-title-container2 {
  height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.thread-title-container3 {
  height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.forum-home-thread-square-textbox img {
  display: none;
}
.trending-card-sub img {
  display: none !important;
}
.trending-thread-content img {
  display: none !important;
}
.forumcontentone-image img {
  display: none !important;
}
.testcar img {
  display: none !important;
}
.thread-header-container button {
  background: transparent;
  color: white;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  height: 50vh;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 50vh;
}
.rich-editor-header-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.thread-create-imagefield-firstdiv {
  display: flex;
  flex-direction: row;
}
.thread-create-imagefield {
  display: flex;
  justify-content: center;
  align-items: center;
}
.isfeatured {
  margin-left: 6vh;
  color: white;
}
.isfeatured input {
  margin-left: 1vh;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .forum-home-thread-square2 {
    width: 41vh;
  }
  .car_list {
    width: 150px !important;
    height: 180px !important;
    padding: 15px 14px !important;
  }
  .car_list h6 {
    font-size: 14px !important;
  }
  .car_list span {
    font-size: 10px;
  }
  /* .swiper-container {
    width: 100%;
    height: 18vh !important;
    margin-top: 99px;
  } */
  .feedback_home {
    margin: 5vh 5vh 0 5vh !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .feedback_home h5 {
    display: flex;
    flex-direction: column;
  }
  .service_list {
    width: unset !important;
    flex-direction: column;
  }
  .container_main {
    padding-top: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
    margin-left: unset !important;
    padding-bottom: 9.5vh;
  }
  .service_cover_img {
    width: 100%;
  }
  .isfeatured {
    margin-left: 2vh;
    color: white;
  }
  .trending-thread-name-n-time p {
    width: 70% !important;
  }
  .create-forum-button {
    width: 12em;
  }
  .create-forum-button-cancel {
    width: 8em;
  }
  .public-DraftEditor-content[contenteditable="true"] {
    padding: 3vh;
  }
  .thread-title-container2 {
    height: auto !important;
  }
  .rich-editor-header-image {
    flex-direction: column;
  }
  .carousel .control-dots .dot {
    opacity: 0.3 !important;
  }
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1 !important;
  }
  .ck.ck-editor {
    width: 40vh !important;
  }
  .thread-title {
    width: 95%;
  }
  .thread-title-sml {
    width: 95%;
  }
  .forum-n-sub {
    width: 40vh !important;
  }
  .forum-n-sub select {
    width: 24vh;
  }
  .thread-create-imagefield-firstdiv {
    width: 40vh;
  }
  .thread-create-imagefield {
    width: 95% !important;
  }
  .thread-create-rich {
    padding: unset !important;
    padding-top: 8em !important;
    padding-bottom: 8em !important;
  }

  .create-thread-button-wrap {
    margin-top: 30vh !important;
  }
  .ImageSpan__root___RoAqL {
    background-size: contain !important;
    width: 30vh !important;
    height: 20vh !important;
  }
  .RichTextEditor__root___2QXK- {
    height: 35vh !important;
    width: 40vh !important;
  }
  .IconButton__icon___3YgOS {
    background-size: 14px;
  }
  .InputPopover__root___3Hpj9 {
    left: -103px !important;
    top: calc(100% + 54px) !important;
  }
  .rdw-editor-wrapper {
    width: 95% !important;
    height: 50vh;
    margin-top: 24px;
  }
  .rdw-editor-main {
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
    height: unset !important;
  }
  .rdw-editor-toolbar {
    background: white !important;
    border: none !important;
  }
  .create-thread-button-wrap {
    margin-top: 10vh !important;
  }
}

.ImageSpan__root___RoAqL {
  background-size: contain !important;
  width: 30vh !important;
  height: 20vh !important;
}
.RichTextEditor__root___2QXK- {
  height: 45vh;
  width: 130vh;
}
.RichTextEditor__editor___1QqIU {
  height: 100% !important;
  background: #121212 !important;
}
.RichTextEditor__root___2QXK- {
  background: rgba(255, 255, 255, 0.08) !important;
  border: none !important;
}
.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
  border: 1px solid rgba(255, 255, 255, 0.08);
}
.Dropdown__root___3ALmx select,
.public-DraftStyleDefault-ltr {
  color: white !important;
  font-family: Montserrat !important;
}
.EditorToolbar__root___3_Aqz {
  border-bottom: none !important;
}
.Dropdown__root___3ALmx select option {
  color: black !important;
}
.rdw-editor-wrapper {
  width: 80%;
  height: 80vh;
}
.back_btn_style_bread_crumb {
  display: flex !important;
  flex-direction: row !important;
  padding-bottom: 24px;
  align-items: center;
}
.back_btn_style_bread_crumb span {
  color: white;
  display: flex;
  align-items: center;
}
.ant-breadcrumb {
  display: flex;
}
.rdw-editor-main {
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  height: 80vh !important;
}
.editor .public-DraftEditorPlaceholder-root {
  color: #999;
  position: absolute;
}
.forumcontentone-image p h2 span {
  color: white !important;
  font-family: Montserrat !important;
}
.ant-btn {
  font-size: 14px !important;
}
.rdw-editor-toolbar {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #121212;
  border: none !important;
}
.public-DraftEditor-content[contenteditable="true"] div span {
  /* color: white !important; */
}
.threadexpand-content p span {
  color: none !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 p span {
  color: white !important;
  font-family: Montserrat !important;
}
.testcar p h4 strong {
  color: white !important;
  font-size: 14px;
  font-family: Montserrat !important;
}
.forum-home-thread-square-textbox p h4 strong {
  color: white;
  font-size: 12px;
  font-family: Montserrat !important;
}
.forum-trending-content h4 strong {
  color: white;
  font-size: 12px;
  font-family: Montserrat !important;
}
.rich-text-manage p,
.rich-text-manage li,
.rich-text-manage ul,
.rich-text-manage a,
.rich-text-manage h1,
.rich-text-manage h2,
.rich-text-manage h3,
.rich-text-manage h4,
.rich-text-manage h5,
.rich-text-manage h6,
.rich-text-manage td,
.rich-text-manage tr,
.rich-text-manage th {
  color: white;
  font-family: Montserrat !important;
}
.rdw-dropdownoption-default {
  color: black;
}
.threadexpand-content h3,
h1,
h4 {
  color: white !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 h2 {
  color: white;
  font-size: 12px;
  font-family: Montserrat !important;
}

.public-DraftEditor-content[contenteditable="true"] div span a span {
  color: #f05c2d !important;
  font-family: Montserrat !important;
}
.threadexpand-content div ul li span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.threadexpand-content div ul li span a span {
  color: #f05c2d !important;
  font-family: Montserrat !important;
}
.threadexpand-content div p span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.thread-expand-data img {
  max-width: 110vh !important;
}
.thread-expand-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.threadexpand-content div p a span {
  color: #f05c2d !important;
  font-family: Montserrat !important;
}
.ck-content .image img {
  width: 60vh !important;
}
.threadexpand-content div h2 span {
  background: transparent !important;
  color: white !important;
  font-family: Montserrat !important;

  font-size: 20px !important;
}
.trending-card-sub h5 ul li span {
  background-color: transparent !important;
  color: white !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 ul li span a span {
  color: #f05c2d !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.testcar p ul li span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.testcar p ul li span a span {
  font-family: Montserrat !important;

  color: #f05c2d !important;
}
.forumcontentone-image p ul li span {
  background-color: transparent !important;
  color: white !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.forumcontentone-image p ul li span a span {
  color: #fc5c2d !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.trending-thread-content p ul li span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;

  font-size: 12px !important;
}
.trending-thread-content p ul li span a span {
  color: #fc5c2d !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.testcar p h2 span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.testcar p h2 span strong {
  color: white !important;
  font-family: Montserrat !important;
}
.testcar p p span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.forum-trending-content p span {
  background-color: transparent !important;
  color: white !important;
  font-size: 1.9vh !important;
  font-family: Montserrat !important;
}
.public-DraftStyleDefault-ltr span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.forumcontentone-image p h2 span {
  background-color: transparent !important;
  font-family: Montserrat !important;
}
.forumcontentone-image p h2 span strong {
  color: white !important;
  font-family: Montserrat !important;
}
.forumcontentone-image p p span {
  background-color: transparent !important;
  color: white !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.forumcontentone-image p p a span {
  color: #f05c2d !important;
  font-family: Montserrat !important;
}
.forum-trending-content h2 span {
  background-color: transparent !important ;
  font-family: Montserrat !important;
}
.forum-trending-content h2 span strong {
  color: white !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 h2 span strong {
  color: white !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 h2 span {
  background-color: transparent !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 p span {
  background-color: transparent !important;
  color: white !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.forum-home-thread-square-textbox p ul li span {
  color: white !important;
  background-color: transparent !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.forum-home-thread-square-textbox p h2 span {
  background-color: transparent !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 h3 strong {
  color: white !important;
  font-size: 12px !important;
  font-family: Montserrat !important;
}
.rdw-dropdown-optionwrapper li {
  color: black !important;
  font-family: Montserrat !important;
}
.rdw-image-alig {
  justify-content: initial !important;
  font-family: Montserrat !important;
}
.DraftEditor-editorContainer > figure > iframe > html > body > img {
  width: 50vh !important;
}
.public-DraftEditor-content[contenteditable="true"] div span img {
  max-width: 125vh !important;
}
.DraftEditor-editorContainer figure iframe {
  width: 60vh;
  height: 40vh;
  overflow: visible;
  object-fit: cover;
}
.forum-trending-content h2 span {
  color: white !important;
  font-size: 1.6vh !important;
  font-family: Montserrat !important;
}
.trending-card-sub h5 h2 span {
  color: white !important;
  font-family: Montserrat !important;

  font-size: 1.3vh !important;
}
.thread-expand-data p a {
  line-break: anywhere;
  font-family: Montserrat !important;
}
.thread-expand-data p span {
  font-family: Montserrat !important;
}
.thread-expand-data h3 span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.testcar h3 p h3 span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;
}
.testcar p h3 {
  color: white !important;
  font-size: 2vh !important;
  font-family: Montserrat !important;
}
.forumcontentone-image p h3 {
  color: white !important;
  font-family: Montserrat !important;

  font-size: 2vh;
}
.forum-trending-content h3 {
  color: white !important;
  font-family: Montserrat !important;

  font-size: 2vh;
}
.trending-card-sub h5 h3 span {
  background-color: transparent !important;
  color: white !important;
  font-family: Montserrat !important;

  font-size: 1.6vh !important;
}
.ant-btn-loading-icon::after {
  transform: none !important;
}
.carousel .slide img {
  object-fit: cover !important;
}

@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .forum-threadtwo > .news > .carousel .slide img {
    height: 40vh !important;
  }
  .service_main_wrap {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .ads_ins_comp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 18px !important;
  }
  .ads_ins_comp img {
    width: 100% !important;
    cursor: pointer;
    padding-bottom: 12px;
  }
  .forum-threadtwo > .news-home > .carousel .slide img {
    height: 40vh !important;
  }
  .forum-threadtwo > .news-home2 > .carousel .slide img {
    height: 40vh !important;
  }
  .banner-image-forum {
    height: 50vh !important;
  }
  .rdw-editor-main img {
    width: 30vh !important;
  }
  .thread-expand-data img {
    width: 42vh !important;
  }
}
.ck.ck-editor__editable_inline {
  color: white !important;
}
@media screen and (min-width: 768px) {
  .home-content h5 {
    padding-top: 12vh !important;
  }
}

.ant-tabs-nav .ant-tabs-tab {
  color: rgba(255, 255, 255, 0.87) !important;
  padding: 1vh;
  /* height: 3vh; */
}
.tags-container::-webkit-scrollbar {
  width: 2px !important;
  height: 3px;
  background: transparent;
}
.tags-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(151, 151, 151, 0.356);
  -webkit-border-radius: 1px;
  border-radius: 1px;
}

.tags-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1px;
  border-radius: 1px;
  background: transparent;
  -webkit-box-shadow: transparent;
}
.tags-container::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}
.tags-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tags-container {
  overflow: scroll;
  justify-content: center;
  align-items: center;
  width: 170vh;
}
.tags-container::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}
.tags-more {
  justify-content: baseline;
  align-items: baseline;
  border: none;
  background: transparent;
  color: #f05c2d;
}
.feedback .ant-btn > .anticon + span,
.ant-btn > span + .anticon,
.anticon svg {
  color: unset !important;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #sign_in_carousel {
    display: none !important;
  }
  .carousel-root {
    width: 100% !important;
  }
  .news-home {
    display: none;
  }
  .news {
    display: none;
  }
  .news-home2 {
    display: block !important;
  }
  .home-banner {
    height: 55vh !important;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      #121212 !important;
  }
  .carousel .slide .testcar {
    bottom: unset !important;
  }
  .testcar h3 {
    font-size: 2.5vh;
  }
  .home-carousel-cover {
    margin-left: 10vw;
    padding-top: 10vh;
  }
  .carousel.carousel-slider {
    height: 55vh;
  }
  .home-forum-threads {
    display: none;
  }
  .home-content-part1 img,
  .home-content-part2 img {
    width: 23vh;
    height: 23vh;
  }
  .home-content-part2 {
    padding-top: 22vh;
  }

  .MuiTypography-alignCenter {
    text-align: right !important;
  }
  .forum-trending-sidebar {
    display: none;
  }
  .forum-home-thread-square {
    margin-left: 4vh !important;
  }
  .thread-create-rich {
    padding-left: 20vw;
  }
  .thread-create-imagefield,
  .thread-title,
  .rdw-editor-wrapper {
    width: 80% !important;
  }
}
.confirm_btn {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-bottom: 54px;
}
.confirm_btn button {
  width: 139px;
  height: 46px;

  background: #ff6f00;
  border-radius: 16px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-transform: capitalize;

  color: #ffffff;
  border: none;
}
.container_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 47px;
  padding-right: 47px;
}
.service_cover_img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}
.explore_btn_1 {
  position: absolute;
  background: transparent;
  border: none;
  left: 56vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #f05c2d;
  cursor: pointer;
}
.explore_btn_1 img {
  padding-left: 10px;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: transparent !important;
}
.service_list_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10vh 10vw;
}
.service_list {
  margin-top: 42px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
.service_list_part1 {
  display: flex;
  justify-content: flex-start;
}
.service_list_part1 {
  width: unset;
}
.service_list img {
  width: 140px !important;
  height: 140px !important;
}
.service_list_wrap {
  padding-left: unset;
}
.row_list_service {
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
}
.service_list_part1 h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  /* identical to box height, or 44% */

  color: #ffffff;
  padding-top: 34px;
}
.service_list img {
  width: 170px;
  height: 170px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #121212;
  border-radius: 6.81196px;
  object-fit: cover;
  margin-bottom: 24px;
}
.service_list h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.ant-empty-description {
  color: white !important;
}
.service_card_wrap {
  position: relative;
}
.vehicle_second {
  display: flex;
  align-items: center;
  padding: 24px 0;
  height: auto;
  min-height: 440px;
  width: 440px;
  background: #121212;
  flex-direction: column;
  border-radius: 12px;
}
.vehicle_second_under img {
  width: 120px;
  cursor: pointer;
}
.vehicle_second_under {
  justify-content: space-between;
  width: 80%;
}
.details_input {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212 !important;
  border-radius: 12px !important;
  padding: 12px !important;
  color: white !important;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 2px solid rgba(255, 255, 255, 0.08) !important;
}
.ant-menu {
  background: unset !important;
}
.ant-menu-submenu-selected {
  color: white !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  color: white !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(121, 104, 104, 0.1) !important;
}
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background: white !important;
}
.service_caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.signup_vehicle_wrap {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.signup_vehicle_wrap p {
  color: rgba(255, 255, 255, 0.6);
  padding-top: 12px;
  white-space: initial;
  width: 100px;
}
.vehicle_wrap {
  width: 240px;
  height: 144px;
  left: 200px;
  top: 320px;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  border-radius: 12px;
  padding: 19px;
  cursor: pointer;
  margin-right: 24px;
}
.delete_vehicle {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
}
.delete_vehicle svg {
  color: #d0342c !important;
  cursor: pointer;
  font-size: 16px !important;
}
.vehicle_wrap p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.38);
}
.vehicle_wrap h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.75px;

  color: rgba(255, 255, 255, 0.87);
}
.service_modal {
  padding: 12px;
  cursor: pointer;
  display: flex;
  box-shadow: 0px 8px 15px 0px rgba(126, 125, 125, 0.2);
  background: white;
  margin-right: 12px;
  flex-direction: column;
  min-width: 120px;
}
.service_modal:hover {
  background: #e4dfdf;
}
.service_modal p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  /* identical to box height, or 114% */

  color: rgba(29, 29, 29, 0.8);
  margin-bottom: 0 !important;
}
.service_modal_wrap {
  display: flex;
}
.store_name {
  padding-top: 52px;
  display: flex;
  flex-direction: column;
}
.store_name h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #ffffff;
  text-transform: capitalize;
}
.store_name h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  color: white;
}
.ant-dropdown-menu {
  background-color: rgba(245, 245, 245, 0.5) !important;
}
.ant-dropdown-trigger {
  background-color: rgba(245, 245, 245, 0.16) !important;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  min-width: 100px !important;
  height: 30px;
}
.car_image_header {
  width: 20px !important;
  height: 20px !important;
  object-fit: cover;
  border-radius: 50%;
  background-image: url("https://images.pexels.com/photos/919073/pexels-photo-919073.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.service_history {
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 90%;
  height: 152px;
  margin-top: 24px;
  padding: 28px 30px;
  border-radius: 12px;
  text-decoration: none !important;
}
.service_history h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #ffffff;
  text-decoration: none !important;
}
.service_history button {
  border: none;
  background-color: transparent;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  text-decoration: none !important;

  /* Primary */

  color: #ff6f00;
  padding-left: 0;
}
.history_table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: transparent;
  margin-top: 24px;
}

.history_table td,
.history_table th {
  border: 1px solid transparent;
  text-align: left;
  padding: 8px;
  color: white;
}
.service_in_history td {
  color: rgba(255, 255, 255, 0.87);
}

.thread_nav {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  width: 45%;
  padding: 9px 15px;
  cursor: pointer;
}
.thread_nav p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  color: #f05c2d;
}
.thread_nav h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #ffffff;
}
.thread_nav:hover {
  background: rgba(255, 255, 255, 0.12);
}
.thread_nav_wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.feedback_home {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  width: 100%;
  height: 108px;
  margin: 5vh 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;
}
.feedback_home h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #ffffff;
  cursor: pointer;
}
.invoice_header {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 170px;
  color: white;
}
.invoice_header h2 {
  color: white;
}
.invoice_header h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
#content h1 {
  color: black;
}
.slick-initialized .slick-slide {
  margin-left: 12px;
}
.slick-track {
  display: flex !important;
}
.nearby_sos {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  width: 30%;
  margin-bottom: 84px;
}
.leaflet-left {
  top: 1em;
  left: 24px;
}
.nearby_sos a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nearby_sos span {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.nearby_sos h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  letter-spacing: 0.1px;

  color: #ffffff;
}
.ev_banner {
  width: 100%;
  cursor: pointer;
}
.ev_modal .ant-modal-body {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212 !important;
  border-radius: 12px;
}
.ev_modal .ant-modal-header {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212 !important;
  display: none;
}
.ev_form_part1 h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 37px;
  line-height: 44px;
  /* or 119% */

  letter-spacing: 0.15px;

  color: white;
}
.ev_form_part1 h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.6);
  padding-top: 15px;
}
.ev_form_part1 button {
  background: #ff6f00;
  border-radius: 16px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-transform: capitalize;

  color: #ffffff;
  width: 280px;
  height: 46px;
  margin-top: 47px;
}
.ev_form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ev_form span {
  width: 50%;
}
.ev_form_part2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ev_form_part2 img {
  width: 70%;
}
.ev_connected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70% !important;
  padding-top: 47px;
}
.ev_connected h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-transform: capitalize;

  color: #2b531a;
}
.ev_form_unauth {
  display: flex;
  flex-direction: column;
}
.ev_form_unauth input {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #0f1c09;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.87);
  padding: 17px 22px;
  border: none;
  margin-top: 12px;
}
.ScratchCard__Canvas {
  border-radius: 50% !important;
  width: 100%;
}
.reward_card {
  width: 412px;
  height: 369px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 62px;
  margin-right: 4vh;
  margin-left: 4vh;
  margin-top: 4vh;
}
.reward_card h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  color: #ffffff;
  padding-top: 20px;
}

.rewards_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.scratched_rew {
  width: 170px;
  height: 170px;
  background: #e2c114;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.scratched_rew h5 {
  position: absolute;
  color: white;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 120px;
  /* identical to box height, or 171% */

  letter-spacing: 0.5px;

  color: #ffffff;
}
.reward_success h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  color: #ffffff;
}
.reward_success h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
.invitation_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inivitation_card {
  width: 90%;
  height: auto;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  border-radius: 12px;
  margin-top: 50px;
  margin-bottom: 122px;
  padding: 26px 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inivitation_card span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inivitation_card span h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 40.0312px;
  line-height: 53px;
  /* identical to box height, or 133% */

  letter-spacing: -1.66562px;

  color: #ffffff;
}
.inivitation_card span h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.15px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
.inivitation_card button {
  width: 219px;
  height: 46px;
  background: #ff6f00;
  border-radius: 16px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-transform: capitalize;

  color: #ffffff;
}
#radio_btns span {
  color: white !important;
}
.fuel_row {
  box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.12);
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.fuel_row h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.15px;

  color: #ffffff;
}
.fuel_row h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: right;
  letter-spacing: 0.4px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
.slick-initialized .slick-slide {
  width: unset !important;
  cursor: pointer;
}

#sign_in_carousel .slick-initialized .slick-slide {
  width: 100% !important;
  margin-left: 0 !important;
}
#sign_in_carousel .slick-initialized .slick-slide img {
  height: 100vh;
  object-fit: cover;
}
.service_head {
  position: relative;
  width: 100%;
}
.service_head h3 {
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 34px;
}
.nav_right {
  display: flex;
  align-items: center;
}
.ant-badge {
  font-size: 22px !important;
}
.ads_ins_comp {
  display: flex;
  justify-content: space-between;
  padding-top: 18px;
}
.ads_ins_comp img {
  width: 48%;
  cursor: pointer;
}
.delete_vehicle2 {
  border: none;
  color: tomato;
  background: transparent;
  display: flex;
  align-items: center;
}

/* .swiper-container {
  width: 100%;
  height: 50vh;
}
.swiper-slide img {
  width: 100%;
  object-fit: cover;
} */
.verification_cover_part1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.verification_cover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.verification_cover_part1 img {
  width: 285px;
  height: 179px;
  object-fit: cover;
}
.verification_cover_part1 h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;

  color: #ffffff;
}
.verification_cover_part1 h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.1px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 18px;
}
.vcph {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.1px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
  padding-top: 18px;
  padding-bottom: 0 !important;
}
.vcpc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.1px;

  /* High Emphasis */

  color: rgba(255, 255, 255, 0.87);
  padding-top: 7px;
}
.verification_cover_part2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.verification_cover_part2 span {
  display: flex;
  align-items: center;
  padding: 18px;
}
.verification_cover_part2 span h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Primary */

  color: #ff6f00 !important;
  padding-left: 38px;
  cursor: pointer;
}
.verification_cover_part2 span input {
  background: linear-gradient(
      0deg,
      rgba(18, 18, 18, 0.85),
      rgba(18, 18, 18, 0.85)
    ),
    #ffffff;
  border-radius: 12px;
  width: 38px !important;
  height: 50px;
  color: white;
  border: none !important;
}
.verification_cover_part2 h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  color: #ffffff;
}
.verification_cover_part3 {
  display: flex;
  flex-direction: column;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  padding-left: 38px;
  color: #ffffff !important;
}
.verification_cover_part3 svg {
  color: green;
  padding-right: 4px;
  font-size: 24px;
}
.steps_verification {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
}
.steps_verification h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
.steps_verification h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Low emphasis */

  color: rgba(255, 255, 255, 0.38);
  padding-left: 18px;
  cursor: pointer;
}
.verfied {
  color: #ff6f00 !important;
}
.vehicle_verified_badge {
  display: flex;
  justify-content: flex-start;
}
.vehicle_verified_badge img {
  width: 20px;
  height: 20px;
}
.vehicle_verified_badge p {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: 0.4px;

  color: #ffffff;
  padding-left: 8px;
}
.verify_in_car {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verify_in_car button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 0.75px;

  /* Primary */

  color: #ff6f00;
}
.verify_in_car img {
  height: 20px;
  padding-left: 29px;
}
#fuel_price .ant-drawer-content-wrapper {
  width: 444px !important;
  background: #333333 !important;
}
.carousel-root {
  width: 100% !important;
}
.carousel.carousel-slider .control-arrow {
  display: none !important;
}
.forum-likebtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
}
