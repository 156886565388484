.below_header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 175px;
}
.below_header ul a li {
  display: inline;
  padding: 8px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #ffffff;
  cursor: 0;
  cursor: pointer;
}
.below_header {
  width: 100%;
  height: 56px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #121212;
  position: fixed;
  top: 93px;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.search_modal input {
  outline: none;
  border: none;
  width: 100%;
  padding: 17px 22px;
  transition: ease-in-out 0.2s;
  color: white!important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  border-radius: 12px;
  height: 58px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;

  /* Low emphasis */

  color: rgba(255, 255, 255, 0.38);
}
.contactsSearch1 {
  background: rgba(255, 255, 255, 0.08) !important;
  border-radius: 12px;
  margin-right: 12px;
}
.search_modal svg {
  font-size: 18px;
  color: rgb(201, 201, 201) !important;
  margin-right: 24px;
}
.contactsTop1 {
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  z-index: 1;
  width: 95%;
  padding: 0 1%;
  height: 40px;
  transition: ease-in-out 0.2s;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
}
.search_modal {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 12px;
  height: 58px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
    margin-bottom: 12px;
}
.search_gen_wrap {
  display: flex;
  flex-direction: column;
  height: auto;
}
.search_second {
  display: flex;
  justify-content: space-between;
}
.search_second_cover {
  padding-top: 24px;
}
.search_second_cover p {
  padding-left: 24px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;

  /* Medium Emphasis */

  color: rgba(255, 255, 255, 0.6);
}
.search_compare {
  padding-left: 41px;
  padding-bottom: 12px;
  padding-top: 12px;
  border-left: 0.5px solid rgb(92, 92, 92);
}
.search_compare a img {
  width: 434px;
  height: 272px;
  object-fit: cover;
}
.search_content {
  width: 100%;
  padding: 24px;
  padding-top: 8px;
}
.search_content_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  padding: 4px 24px;
  color: white;
  margin-bottom: 8px;
}
.search_type {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 2px;
  padding: 1px 8px;
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.ant-modal-content {
  border-radius: 12px !important;
}

.search_filter {
  display: flex;
}
.search_filter span {
  border-radius: 16px;
  border: 0.5px solid rgb(90, 90, 90);
  background: transparent;
  margin-left: 24px;
  color: white;
  padding: 6px 12px;
  cursor: pointer;
}
.selected_search_radio {
  background: #ff6f00 !important;
}
.empty_search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 278px;
}
.mob_more {
  display: none;
}
#drawer_wrapper .ant-drawer-content-wrapper {
  height: 100% !important;
}
#drawer_wrapper .ant-drawer-header {
  padding-left: 12px;
}
.drawer_close_icon svg {
  font-size: 24px;
  position: absolute;
  top: 16px;
  left: 6px;
  color: white;
}
#drawer_wrapper .ant-drawer-header {
  border-radius: unset !important;
  display: none;
}
#drawer_wrapper .ant-drawer-body {
  position: relative;
}
.top_drawer_home {
  padding-top: 60px;
}
.top_drawer_home ul {
  list-style-type: none;
  padding-left: 0;
}
.top_drawer_home ul li {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  color: #ffffff;
  padding-bottom: 31px;
}
.top_left_drawer_logo img {
  width: 120px;
}
.mob_more_account {
  display: none;
}
.sidebar_profile {
  height: 116px;
  width: 116px;
  border-radius: 50%;
}
.sidebar_profile img {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  object-fit: cover;
  background: url("../assets/moto365logo.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.sidebar_cover {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 40%;
}
.sidebar_cover h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  color: #ffffff;
  text-align: left;
}
@media screen and (max-width: 786px) and (max-aspect-ratio: 13/9) and (orientation: portrait) {
  .search_second {
    flex-direction: column;
  }
  div.car-detail {
    margin-left: 12px !important;
  }
  .mob_more {
    display: block;
    border: none;
    background: transparent;
  }
  .mob_more svg {
    color: white;
    font-size: 24px;
  }
  .search_compare {
    padding-left: unset;
    padding-bottom: 12px;
    padding-top: 12px;
    border-top: 0.5px solid rgb(92, 92, 92);
    border-left: unset;
  }
  .search_compare a img {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
  .search_filter {
    flex-wrap: wrap;
  }
  .search_filter span {
    font-size: 8px;
    width: 120px;
    margin-left: 0;
    margin-top: 8px;
  }
  .search_content {
    padding-left: 0;
    padding-right: 0;
  }
  .search_content_row span {
    font-size: 10px;
  }
  .mob_more_account {
    display: block;
  }
  .accnt_main_nav {
    display: none;
  }
}
#drawer_wrapper_right .ant-drawer-content-wrapper {
  width: 283px !important;
}
.navbar_account {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 320px;
  height: 278px;
}
.navbar_account span img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.navbar_account span {
  border-bottom: 0.5px solid rgb(90, 90, 90);
  width: 100%;
  padding-bottom: 24px;
  text-align: center;
  display: flex;
}
.navbar_account span p {
  padding-left: 31px;
  padding-top: 7%;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  color: #ffffff;
}
.navbar_account_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
}
.navbar_account_content p {
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  color: #ffffff;
}
.navbar_account_content p:hover {
  color: #f05c2d;
}
